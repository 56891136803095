<div class="container">
    <br>
  <div class="document-box">
      <ul class="document-list">
          <h3>Сертификаты</h3>
          <span class="mr-3" style="font-size: 30px; color: #ea5e1f;"><i class="far fa-file-pdf"></i></span>
          <a href="/assets/files/Сэндвич-панели  МВУ. Кровельные по ГОСТ 32603-2021. Сертификат огнестойкости до 11.04.27г.pdf"
            target="_blank">Сэндвич-панели  МВУ. Кровельные по ГОСТ 32603-2021. Сертификат огнестойкости до 11.04.27г.</a> ( 4685 Кб ) <br>
          <span class="mr-3" style="font-size: 30px; color: #ea5e1f;"><i class="far fa-file-pdf"></i></span>
          <a href="/assets/files/Сэндвич-панели МВУ. Кровельные  ТУ. Сертификат огнестойкости до 16.03.28г.pdf"
            target="_blank">Сэндвич-панели МВУ. Кровельные  ТУ. Сертификат огнестойкости до 16.03.28г</a> ( 2616 Кб ) <br>
          <span class="mr-3" style="font-size: 30px; color: #ea5e1f;"><i class="far fa-file-pdf"></i></span>
          <a href="/assets/files/Сэндвич-панели МВУ. Стенововые и кровельные по ГОСТ 32603-2021. Сертификат соотвествия до 31.03.25г.pdf" target="_blank">
            Сэндвич-панели МВУ. Стенововые и кровельные по ГОСТ 32603-2021. Сертификат соотвествия до 31.03.25г.</a> ( 3294 Кб ) <br>
          <span class="mr-3" style="font-size: 30px; color: #ea5e1f;"><i class="far fa-file-pdf"></i></span>
          <a href="/assets/files/Сэндвич-панели МВУ. Стеновые и кровельные по ТУ. Сертификат соответствия до 03.02.26г.pdf" target="_blank">
            Сэндвич-панели МВУ. Стеновые и кровельные по ТУ. Сертификат соответствия до 03.02.26г.</a> ( 4281 Кб ) <br>
          <span class="mr-3" style="font-size: 30px; color: #ea5e1f;"><i class="far fa-file-pdf"></i></span>
          <a href="/assets/files/Сэндвич-панели МВУ. Стеновые по ГОСТ 32603-2021. Сертификат огнестойкости до 11.04.27г.pdf" target="_blank">
            Сэндвич-панели МВУ. Стеновые по ГОСТ 32603-2021. Сертификат огнестойкости до 11.04.27г</a> ( 4779 Кб ) <br>
          <span class="mr-3" style="font-size: 30px; color: #ea5e1f;"><i class="far fa-file-pdf"></i></span>
          <a href="/assets/files/Сэндвич-панели МВУ. Стеновые по ТУ. Сертификат огнестойкости до 16.03.28г.pdf" target="_blank">
            Сэндвич-панели МВУ. Стеновые по ТУ. Сертификат огнестойкости до 16.03.28г.</a> ( 2573 Кб ) <br>
          <span class="mr-3" style="font-size: 30px; color: #ea5e1f;"><i class="far fa-file-pdf"></i></span>
          <a href="/assets/files/Сэндвич-панели ПИР. Кровельные. Сертификат огнестойкости до 22.04.24г.pdf" target="_blank">
            Сэндвич-панели ПИР. Кровельные. Сертификат огнестойкости до 22.04.24г.</a> ( 501 Кб ) <br>
          <span class="mr-3" style="font-size: 30px; color: #ea5e1f;"><i class="far fa-file-pdf"></i></span>
          <a href="/assets/files/Сэндвич-панели ПИР. Кровельные. Сертификат соответствия до 08.06.26г.pdf" target="_blank">
            Сэндвич-панели ПИР. Кровельные. Сертификат соответствия до 08.06.26г.</a> ( 528 Кб ) <br>
          <span class="mr-3" style="font-size: 30px; color: #ea5e1f;"><i class="far fa-file-pdf"></i></span>
          <a href="/assets/files/Сэндвич-панели ПИР. Стеновые. Сертификат огнестойкости до 22.04.24г.pdf" target="_blank">
            Сэндвич-панели ПИР. Стеновые. Сертификат огнестойкости до 22.04.24г.</a> ( 496 Кб ) <br>
          <span class="mr-3" style="font-size: 30px; color: #ea5e1f;"><i class="far fa-file-pdf"></i></span>
          <a href="/assets/files/Сэндвич-панели ПИР. Стеновые. Сертификат соответствия до 08.06.26г.pdf" target="_blank">
            Сэндвич-панели ПИР. Стеновые. Сертификат соответствия до 08.06.26г.</a> ( 528 Кб ) <br>
          <span class="mr-3" style="font-size: 30px; color: #ea5e1f;"><i class="far fa-file-pdf"></i></span>
          <a href="/assets/files/Профнастил  ГОСТ 24045-2016 Сертификат соответствия до 08.06.26г.pdf" target="_blank">
            Профнастил  ГОСТ 24045-2016 Сертификат соответствия до 08.06.26г.</a> ( 523 Кб ) <br>
          <span class="mr-3" style="font-size: 30px; color: #ea5e1f;"><i class="far fa-file-pdf"></i></span>
          <a href="/assets/files/Профнастил  ТУ 1122-130-15459388-2017  Сертификат соответствия до 06. 06.26г.pdf" target="_blank">
            Профнастил  ТУ 1122-130-15459388-2017  Сертификат соответствия до 06. 06.26г.</a> ( 565 Кб ) <br>
          
      </ul>
      <br>
      
  </div><br><br>
  </div>
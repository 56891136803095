import {Injectable} from "@angular/core";
import {HttpClient, HttpParams } from "@angular/common/http";
// import 'rxjs/add/operator/map';
// import {News} from "../../shared/models/news.model";
// import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class ObjectsService {
  constructor(private http: HttpClient) {}
   
//метод получения объектов
  getObjects() {
    return this.http.get('//specialist-kazan.ru/objectsdata');

 }
  
 //метод получения определенного количества объектов
  getObjectsCarousel(count: Number) {
    return this.http.get('//specialist-kazan.ru/objectsdata/count/'+ count);
  }
}

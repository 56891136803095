<!-- Footer -->
<div class="container  fixed-bottom bg-light text-dark pt-2 pb-2">
  <div class="row">
    <div class="col-6  col-sm-6 footer-text pt-1">© {{now|date: 'yyyy'}} ООО «Специалист-Казань» <br>
    </div>

    <div class="col-6 text-right col-sm-6 footer-text">
      <!-- <a target="_blank" href="https://www.facebook.com/SpecialistKazan/">
        <i class="fab fa-facebook-square pl-2" style="font-size:31px;color: #22b0c9;"></i></a> -->

      <a target="_blank" href="#">
        <i class="fab fa-vk pl-2" style="font-size:31px;color: #486b99;"></i></a>

      <!--  <a target="_blank" href="https://instagram.com/specialistkazan_?igshid=w0zgain5aepw">
        <i class="fab fa-instagram pl-2" style="font-size:31px;color: #b56db8;"></i></a> -->

      <a target="_blank" href="#">
        <i class="fab fa-youtube-square pl-2" style="font-size:31px;color: #e94040;"></i></a> 

    </div>
  </div>
</div>
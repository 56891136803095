<div class="container">
    <br>
    <h4> ФАСОННЫЕ ЭЛЕМЕНТЫ</h4> <br>
    <p>Фасонные элементы для сэндвич панелей обеспечивают герметичность и теплоизоляцию в местах стыка панелей Teplant
        (Теплант). Они изготавливаются из того же металла, что и сэндвич-панели Teplant (Теплант). Богатая цветовая
        гамма позволяет визуально не только выделить или скрыть фасонные элементы, но и создать неповторимый стиль и
        придать законченность в оформлении фасада.</p>
    <p>В зависимости от требуемого декоративного эффекта разделяют фасонные элементы для системы скрытого крепежа и
        фасонные элементы для системы видимого крепежа.</p>
    <p>Компания Теплант располагает уникальным оборудованием для изготовления фасонных элементов длиной до 10 м.
        Фасонные элементы, выпускаемые предприятием, отличаются высочайшим качеством.</p>
    <a href="/src/assets/files/Каталог_технических_решений.pdf" target="_blank"><em>Конструктивные решения
            Teplant</em></a>
    <br>
    <br><br><br><br><br>
</div>
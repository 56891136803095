<div class="container">
  <br>
  <h4> КРОВЕЛЬНЫЕ СЭНДВИЧ-ПАНЕЛИ</h4> <br>
  
  <div class="btn-mobile">
    <div class="btn-group" role="group" aria-label="Basic example">
      <a href="production/krovelnie-paneli/#main-char" class="linkW btn btn-info p-3" role="button">Основные
        характеристики</a>
      <a href="production/krovelnie-paneli/#load" class="btn btn-secondary p-3" role="button">Допустимые расчетные
        нагрузки</a>
      <a href="production/krovelnie-paneli/#knots" class="btn btn-info p-3" role="button">Узлы примыкания</a>
    </div><br><br>
  </div>

  <p>Кровельные сэндвич-панели Теплант данного типа (имеют маркировку ПКБ) применяются для производства кровли на
    торговых, промышленных и гражданских объектах. Допускается использовать здания с кровельными конструкциями,
    выполненными из сэндвич-панелей, в I-VI районах по ветровой нагрузке согласно СНиП 2.01.07.85.Состоит из
    профилированного листа из оцинкованной стали с защитным полимерным покрытием толщиной 0,7мм и 0,5мм (верхний и
    нижний лист соответственно) и утеплителя из минеральной тонковолокнистой ваты на основе базальтового волокна на
    синтетическом связующем с гидрофобизирующими добавками с вертикальной ориентацией волокон.ТУ 5284-013-01395087-2001.
  </p>
  <p>В качестве среднего теплоизоляционного слоя кровельной панели Теплант применяются ламели длиной 2400 мм из
    минеральной ваты VattaRus на основе базальтового волокна на синтетическом связующем с гидрофобизирующими добавками
    плотностью 110 кг/м3 <span class="orange-bold">Teplant – Concept (Концепт)</span> и плотностью 95 кг/м3 <span
      class="orange-bold">(Teplant – Universal (Универсал)</span></p>
  <p>Базовые стандартные цвета: 1014, 1015, 1018, 3003, 3020, 5002, 5005, 5024, 6002, 6005, 7004, 7035, 8017, 9002,
    9003, 9006, 9010.</p>
  <br>
  <h5 id="main-char">ХАРАКТЕРИСТИКА КРОВЕЛЬНЫХ ПАНЕЛЕЙ TEPLANT – CONCEPT (КОНЦЕПТ)</h5> <br>
  <div class="table-cover">
    <table class="table table-bordered" style="width: 902px; text-align: center;">
      <thead class="table-info p-1" style="font-size: 13px;">
        <tr>
          <th scope="col">Ширина, мм</th>
          <th scope="col">Длина, мм</th>
          <th scope="col">Толщина, мм</th>
          <th scope="col">Приведенное сопротивление теплопередаче, Ro (м2х<sup _ngcontent-kwv-c23="">o</sup>С/Вт)</th>
          <th scope="col">Вес, кг/м2</th>
          <th scope="col">Предел огнестойкости</th>
          <th scope="col">Горючесть утеплителя, ГОСТ 30244</th>
        </tr>
      </thead>
      <tbody>

        <tr>
          <td class="align-middle" rowspan="6">1 000</td>
          <td class="align-middle" rowspan="6">До <br>14000</td>
          <td>50</td>
          <td>1,452</td>
          <td>18,9</td>
          <td>--</td>
          <td class="align-middle" rowspan="6">НГ</td>
        </tr>
        <tr>
          <td>80</td>
          <td>2,191</td>
          <td>22,5</td>
          <td>RE-30</td>
        </tr>
        <tr>
          <td>100</td>
          <td>2,682</td>
          <td>24,9</td>
          <td>RE-30</td>
        </tr>
        <tr>
          <td>120</td>
          <td>3,171</td>
          <td>27,3</td>
          <td>RE-30</td>
        </tr>
        <tr>
          <td>150</td>
          <td>3,905</td>
          <td>30,9</td>
          <td>RE-30</td>
        </tr>
        <tr>
          <td>200</td>
          <td>5,1276</td>
          <td>36,9</td>
          <td>RE-30</td>
        </tr>
      </tbody>
    </table>
  </div>

  <div class="table-cover">
    <table class="table table-bordered " style="text-align: center;">
      <thead class="table-info p-1" style="font-size: 13px;">
        <tr>
          <th scope="col">Коэффициент теплопроводности 10, Вт/мК</th>
          <th scope="col">Коэффициент теплопроводности 25, Вт/мК</th>
          <th scope="col">Коэффициент теплопроводности А, Вт/мК </th>
          <th scope="col">Коэффициент теплопроводности B, Вт/мК </th>
          <th class="align-middle" scope="col">Сжимаемость, не более, %</th>
          <th scope="col">Прочность на отрыв слоев,МПа</th>
          <th scope="col">Водопоглощение по объему, не более, %</th>
          <th class="align-middle" scope="col">Паропроницаемость, мг/(м*чПа)</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>0,032</td>
          <td>0,036</td>
          <td>0,038</td>
          <td>0,040</td>
          <td>4</td>
          <td>0,003</td>
          <td>2</td>
          <td>0,55</td>
        </tr>
      </tbody>
    </table>
  </div>
  <br>

  <h5>ХАРАКТЕРИСТИКА КРОВЕЛЬНЫХ ПАНЕЛЕЙ TEPLANT – UNIVERSAL (УНИВЕРСАЛ)</h5> <br>
  <div class="table-cover">
    <table class="table table-bordered" style="width: 902px; text-align: center;">
      <thead class="table-info p-1" style="font-size: 13px;">
        <tr>
          <th scope="col">Ширина, мм</th>
          <th scope="col">Длина, мм</th>
          <th scope="col">Толщина, мм</th>
          <th scope="col">Приведенное сопротивление теплопередаче, Ro (м2х<sup _ngcontent-kwv-c23="">o</sup>С/Вт)</th>
          <th scope="col">Вес, кг/м2</th>
          <th scope="col">Предел огнестойкости</th>
          <th scope="col">Горючесть утеплителя, ГОСТ 30244</th>
        </tr>
      </thead>
      <tbody>

        <tr>
          <td class="align-middle" rowspan="6">1 000</td>
          <td class="align-middle" rowspan="6">До <br>12000</td>
          <td>80</td>
          <td>2,239</td>
          <td>19,8</td>
          <td>RE-30</td>
          <td class="align-middle" rowspan="6">НГ</td>
        </tr>
        <tr>
          <td>100</td>
          <td>2,743</td>
          <td>21,8</td>
          <td>RE-30</td>
        </tr>
        <tr>
          <td>120</td>
          <td>3,244</td>
          <td>23,8</td>
          <td>RE-30</td>
        </tr>
        <tr>
          <td>150</td>
          <td>3,996</td>
          <td>26,8</td>
          <td>RE-30</td>
        </tr>
        <tr>
          <td>200</td>
          <td>5,249</td>
          <td>31,8</td>
          <td>RE-30</td>
        </tr>

      </tbody>
    </table>
  </div>

  <div class="table-cover">
    <table class="table table-bordered " style="text-align: center;">
      <thead class="table-info p-1" style="font-size: 13px;">
        <tr>
          <th scope="col">Коэффициент теплопроводности 10, Вт/мК</th>
          <th scope="col">Коэффициент теплопроводности 25, Вт/мК</th>
          <th scope="col">Коэффициент теплопроводности А, Вт/мК </th>
          <th scope="col">Коэффициент теплопроводности B, Вт/мК </th>
          <th class="align-middle" scope="col">Сжимаемость, не более, %</th>
          <th scope="col">Прочность на отрыв слоев,МПа</th>
          <th scope="col">Водопоглощение по объему, не более, %</th>
          <th class="align-middle" scope="col">Паропроницаемость, мг/(м*чПа)</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>0,032</td>
          <td>0,036</td>
          <td>0,039</td>
          <td>0,042</td>
          <td>6</td>
          <td>0,003</td>
          <td>2</td>
          <td>0,56</td>
        </tr>
      </tbody>
    </table>
  </div>
  <br>
  <h5 id="load">ДОПУСТИМЫЕ РАСЧЕТНЫЕ НАГРУЗКИ НА КРОВЕЛЬНЫЕ ПАНЕЛИ</h5><br>
  <ul>
    <li><a href="/assets/files/Расчетные нагрузки Теплант Концепт.pdf" target="_blank"><em>Teplant – Concept
          (Концепт)</em></a></li>
    <li><a href="/assets/files/Расчетные нагрузки Теплант Универсал.pdf" target="_blank"><em>Teplant – Universal
          (Универсал)</em></a></li>
  </ul>

  <p><span class="orange-bold">Кровельные сэндвич-панели Теплант</span>, также как и <span class="orange-bold">стеновые
      панели Теплант</span>, отличаются легкостью и огнестойкостью.
    Если при монтаже обеспечено надежное примыкание кровли, применение панелей гарантирует минимальные тепловые потери,
    а значит экономию энергии.</p>
  <ul>
    <li><a id="knots" href="/assets/files/Каталог_технических_решений.pdf" target="_blank"><em>Узлы примыкания</em></a>
    </li>
    <li><a href="/assets/files/Инструкция по монтажу.pdf" target="_blank"><em>ИС 5284-013-01395087-2012</em></a></li>
  </ul>

  <p>Срок службы кровельных панелей Теплант не менее 20 лет, при соблюдении условий транспортирования, хранения, монтажа
    и эксплуатации.</p> <br>

  <br><br>
</div>
<div class="bg"> </div>

<div class="container  mt-n2 xz">
  <div class="row">
    <div id="layer1" class="col-md-3 mt-4 pl-0 pr-0 logospec" style="max-width: 300px;"><a routerLink=""><img
          id="spec_logo" src="/assets/images/spec.png"></a></div>
    <div class="col-md-3  mt-4 text-center phonesMob" style="max-width: 255px;">
      <span style="font-size: 21px; color: #ea5e1f;"><i class="far fa-phone-square-alt"></i> +7 (843)
        528-27-01</span><br>
      <span style="font-size: 21px; color: #ea5e1f;"><i class="far fa-phone-square-alt"></i> +7 (843)
        528-27-03</span><br>
      <span style="font-size: 21px; color: #ea5e1f;"><i class="far fa-phone-square-alt"></i> +7 (987) 415-97-45</span>
    </div>
    <div class="col-md-4 mt-4 pl-0 text-left adresMob" style="max-width: 345px;">
      <span style="font-size: 21px; color: #ea5e1f;"><i class="fad fa-map-marker-alt "></i> Казань, пр.Ямашева 45а,
        оф.501</span> <br>
      <span style="font-size: 15px; color: #ea5e1f;"><i class="fal fa-envelope fa-2x" style="color:#f8af01;"></i><a
          style="font-size: 1.4em;" href="mailto:office@specialist-kazan.ru"> office@specialist-kazan.ru</a></span><br>
      <span style="font-size: 21px; color: #ea5e1f;"><i class="fad fa-map-marker-alt "></i><a> Набережные
          Челны</a></span>
    </div>
    <div class="col-md-2">
      <div class="row">
        <a href="https://elshield.com/" target="_blank">
          <div class="text-center offset-md-1 "><img id="logo_teplant" src="/assets/images/logo.png"></div>
        </a>
        <a href="https://elshield.com/" target="_blank">
          <div class="text-center "><img id="logo_elsh" src="/assets/images/logoEl_2.png"></div>
        </a>
      </div>
    </div>
  </div>

  <app-navbar></app-navbar>
</div>
import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-corrugated-board',
  templateUrl: './corrugated-board.component.html',
  styleUrls: ['./corrugated-board.component.scss']
})
export class CorrugatedBoardComponent implements OnInit {

  constructor(private http: HttpClient) { }

  ngOnInit(): void {
    // this.http.get('https://http://specialist-kazan.ru/corrugated_board.aspx')
    // .subscribe(response => {
    //   console.log('Response', response)
    // }) 
  }





 items = [
  {
    "FIELD1": "C10-1000-0,6",
    "FIELD2": "6,6",
    "FIELD3": "5,6",
    "FIELD4": "5,6",
    "FIELD5": "",
    "FIELD6": "",
    "FIELD7": "",
    "FIELD8": "0,8",
    "FIELD9": "",
    "FIELD10": ""
  },
  {
    "FIELD1": "C10-1000-0,7",
    "FIELD2": "7,7",
    "FIELD3": "6,5",
    "FIELD4": "6,5",
    "FIELD5": "",
    "FIELD6": "",
    "FIELD7": "",
    "FIELD8": "0,98",
    "FIELD9": "",
    "FIELD10": ""
  },
  {
    "FIELD1": "C10-1100-0,5",
    "FIELD2": "6,25",
    "FIELD3": "5,42",
    "FIELD4": "4,93",
    "FIELD5": "0,86",
    "FIELD6": "2,92",
    "FIELD7": "1,22",
    "FIELD8": "0,66",
    "FIELD9": "1,74",
    "FIELD10": "1,15"
  },
  {
    "FIELD1": "C10-1100-0,6",
    "FIELD2": "7,5",
    "FIELD3": "6,41",
    "FIELD4": "5,83",
    "FIELD5": "1,01",
    "FIELD6": "3,4",
    "FIELD7": "1,43",
    "FIELD8": "0,82",
    "FIELD9": "1,55",
    "FIELD10": "1,31"
  },
  {
    "FIELD1": "C10-1100-0,7",
    "FIELD2": "8,75",
    "FIELD3": "7,39",
    "FIELD4": "6,72",
    "FIELD5": "1,15",
    "FIELD6": "3,85",
    "FIELD7": "1,63",
    "FIELD8": "0,99",
    "FIELD9": "1,98",
    "FIELD10": "1,52"
  },
  {
    "FIELD1": "C21-1000-0,4",
    "FIELD2": "5",
    "FIELD3": "4,27",
    "FIELD4": "4,27",
    "FIELD5": "3,14",
    "FIELD6": "1,95",
    "FIELD7": "2,08",
    "FIELD8": "3,12",
    "FIELD9": "2,68",
    "FIELD10": "1,48"
  },
  {
    "FIELD1": "C21-1000-0,45",
    "FIELD2": "5,63",
    "FIELD3": "4,76",
    "FIELD4": "4,76",
    "FIELD5": "3,74",
    "FIELD6": "2,43",
    "FIELD7": "2,56",
    "FIELD8": "3,71",
    "FIELD9": "2,85",
    "FIELD10": "2,16"
  },
  {
    "FIELD1": "C21-1000-0,5",
    "FIELD2": "6,25",
    "FIELD3": "5,25",
    "FIELD4": "5,25",
    "FIELD5": "4,34",
    "FIELD6": "2,91",
    "FIELD7": "3,04",
    "FIELD8": "4,3",
    "FIELD9": "3,02",
    "FIELD10": "2,84"
  },
  {
    "FIELD1": "C21-1000-0,55",
    "FIELD2": "6,88",
    "FIELD3": "5,74",
    "FIELD4": "5,74",
    "FIELD5": "5,01",
    "FIELD6": "4,4",
    "FIELD7": "4,5",
    "FIELD8": "4,96",
    "FIELD9": "4,76",
    "FIELD10": "4,1"
  },
  {
    "FIELD1": "C21-1000-0,6",
    "FIELD2": "7,5",
    "FIELD3": "6,23",
    "FIELD4": "6,23",
    "FIELD5": "5,49",
    "FIELD6": "4,83",
    "FIELD7": "4,96",
    "FIELD8": "5,46",
    "FIELD9": "5,24",
    "FIELD10": "4,51"
  },
  {
    "FIELD1": "C21-1000-0,7",
    "FIELD2": "8,75",
    "FIELD3": "7,21",
    "FIELD4": "7,21",
    "FIELD5": "6,32",
    "FIELD6": "6,07",
    "FIELD7": "5,81",
    "FIELD8": "6,28",
    "FIELD9": "6,14",
    "FIELD10": "5,66"
  },
  {
    "FIELD1": "C21-1000-0,8",
    "FIELD2": "10",
    "FIELD3": "8,19",
    "FIELD4": "8,19",
    "FIELD5": "7,22",
    "FIELD6": "6,9",
    "FIELD7": "6,62",
    "FIELD8": "7,16",
    "FIELD9": "7",
    "FIELD10": "6,45"
  },
  {
    "FIELD1": "HC35-1000-0,5",
    "FIELD2": "6,25",
    "FIELD3": "5,25",
    "FIELD4": "5,25",
    "FIELD5": "11,68",
    "FIELD6": "6,78",
    "FIELD7": "5,58",
    "FIELD8": "13,46",
    "FIELD9": "7,8",
    "FIELD10": "7,25"
  },
  {
    "FIELD1": "HC35-1000-0,55",
    "FIELD2": "6,88",
    "FIELD3": "5,74",
    "FIELD4": "5,74",
    "FIELD5": "13,6",
    "FIELD6": "7,78",
    "FIELD7": "7,5",
    "FIELD8": "14",
    "FIELD9": "8,4",
    "FIELD10": "7,6"
  },
  {
    "FIELD1": "HC35-1000-0,6",
    "FIELD2": "6,4",
    "FIELD3": "6,23",
    "FIELD4": "6,23",
    "FIELD5": "15,41",
    "FIELD6": "9,25",
    "FIELD7": "8,4",
    "FIELD8": "14,92",
    "FIELD9": "8,56",
    "FIELD10": "8,27"
  },
  {
    "FIELD1": "HC35-1000-0,7",
    "FIELD2": "8,75",
    "FIELD3": "7,21",
    "FIELD4": "7,21",
    "FIELD5": "17,87",
    "FIELD6": "10,73",
    "FIELD7": "9,74",
    "FIELD8": "17,36",
    "FIELD9": "9,95",
    "FIELD10": "9,58"
  },
  {
    "FIELD1": "HC35-1000-0,8",
    "FIELD2": "10",
    "FIELD3": "8,19",
    "FIELD4": "8,19",
    "FIELD5": "20,25",
    "FIELD6": "12,16",
    "FIELD7": "11,04",
    "FIELD8": "19,89",
    "FIELD9": "11,44",
    "FIELD10": "10,92"
  },
  {
    "FIELD1": "C44-1000-0,5",
    "FIELD2": "6,25",
    "FIELD3": "5,25",
    "FIELD4": "5,25",
    "FIELD5": "13,01",
    "FIELD6": "4,61",
    "FIELD7": "6,13",
    "FIELD8": "19,03",
    "FIELD9": "5,82",
    "FIELD10": "6,09"
  },
  {
    "FIELD1": "C44-1000-0,55",
    "FIELD2": "6,88",
    "FIELD3": "5,74",
    "FIELD4": "5,74",
    "FIELD5": "19,6",
    "FIELD6": "7,25",
    "FIELD7": "11,8",
    "FIELD8": "16,1",
    "FIELD9": "6,4",
    "FIELD10": "6,6"
  },
  {
    "FIELD1": "C44-1000-0,6",
    "FIELD2": "7,5",
    "FIELD3": "6,23",
    "FIELD4": "6,23",
    "FIELD5": "21,4",
    "FIELD6": "7,9",
    "FIELD7": "12,8",
    "FIELD8": "17,5",
    "FIELD9": "6,9",
    "FIELD10": "7,1"
  },
  {
    "FIELD1": "C44-1000-0,7",
    "FIELD2": "8,8",
    "FIELD3": "7,21",
    "FIELD4": "7,21",
    "FIELD5": "25,4",
    "FIELD6": "9,3",
    "FIELD7": "15,2",
    "FIELD8": "20,7",
    "FIELD9": "8,3",
    "FIELD10": "8,5"
  },
  {
    "FIELD1": "C44-1000-0,8",
    "FIELD2": "10",
    "FIELD3": "8,19",
    "FIELD4": "8,19",
    "FIELD5": "29",
    "FIELD6": "10,6",
    "FIELD7": "17,3",
    "FIELD8": "23,5",
    "FIELD9": "9,4",
    "FIELD10": "9,7"
  },
  {
    "FIELD1": "HC44-1000-0,7",
    "FIELD2": "8,94",
    "FIELD3": "7,01",
    "FIELD4": "7,01",
    "FIELD5": "31,18",
    "FIELD6": "12,83",
    "FIELD7": "15,82",
    "FIELD8": "30,47",
    "FIELD9": "12,81",
    "FIELD10": "11,31"
  },
  {
    "FIELD1": "HC44-1000-0,8",
    "FIELD2": "10,2",
    "FIELD3": "8",
    "FIELD4": "8",
    "FIELD5": "35,43",
    "FIELD6": "14,59",
    "FIELD7": "17,98",
    "FIELD8": "35,43",
    "FIELD9": "15,24",
    "FIELD10": "13,69"
  },
  {
    "FIELD1": "HC44-1000-0,9",
    "FIELD2": "11,35",
    "FIELD3": "8,9",
    "FIELD4": "8,9",
    "FIELD5": "39,17",
    "FIELD6": "16,11",
    "FIELD7": "19,88",
    "FIELD8": "39,17",
    "FIELD9": "15,74",
    "FIELD10": "17,4"
  },
  {
    "FIELD1": "H57-750-0,6",
    "FIELD2": "6,6",
    "FIELD3": "5,48",
    "FIELD4": "7,31",
    "FIELD5": "46,2",
    "FIELD6": "12",
    "FIELD7": "18",
    "FIELD8": "46,2",
    "FIELD9": "13,8",
    "FIELD10": "15,9"
  },
  {
    "FIELD1": "H57-750-0,7",
    "FIELD2": "7,7",
    "FIELD3": "6,35",
    "FIELD4": "8,46",
    "FIELD5": "53,8",
    "FIELD6": "14,8",
    "FIELD7": "21,1",
    "FIELD8": "53,8",
    "FIELD9": "16,4",
    "FIELD10": "19,7"
  },
  {
    "FIELD1": "H57-750-0,8",
    "FIELD2": "8,8",
    "FIELD3": "7,21",
    "FIELD4": "9,61",
    "FIELD5": "61,2",
    "FIELD6": "17,9",
    "FIELD7": "24,4",
    "FIELD8": "61,2",
    "FIELD9": "18,9",
    "FIELD10": "24"
  },
  {
    "FIELD1": "H60-845-0,6",
    "FIELD2": "7,5",
    "FIELD3": "6,23",
    "FIELD4": "7,37",
    "FIELD5": "55,5",
    "FIELD6": "13,1",
    "FIELD7": "23",
    "FIELD8": "46,4",
    "FIELD9": "14",
    "FIELD10": "14,9"
  },
  {
    "FIELD1": "H60-845-0,7",
    "FIELD2": "8,8",
    "FIELD3": "7,21",
    "FIELD4": "8,54",
    "FIELD5": "62,1",
    "FIELD6": "14,6",
    "FIELD7": "24,4",
    "FIELD8": "59,1",
    "FIELD9": "16,5",
    "FIELD10": "18,7"
  },
  {
    "FIELD1": "H60-845-0,8",
    "FIELD2": "10",
    "FIELD3": "8,19",
    "FIELD4": "9,7",
    "FIELD5": "70,6",
    "FIELD6": "17,7",
    "FIELD7": "28,1",
    "FIELD8": "69,9",
    "FIELD9": "19",
    "FIELD10": "22,7"
  },
  {
    "FIELD1": "H60-845-0,9",
    "FIELD2": "11,3",
    "FIELD3": "9,18",
    "FIELD4": "10,86",
    "FIELD5": "79",
    "FIELD6": "20,9",
    "FIELD7": "31,8",
    "FIELD8": "78,7",
    "FIELD9": "21,5",
    "FIELD10": "27"
  },
  {
    "FIELD1": "H75-750-0,7",
    "FIELD2": "8,8",
    "FIELD3": "7,21",
    "FIELD4": "9,62",
    "FIELD5": "104,5",
    "FIELD6": "22,5",
    "FIELD7": "29,1",
    "FIELD8": "104,5",
    "FIELD9": "25,6",
    "FIELD10": "28,1"
  },
  {
    "FIELD1": "H75-750-0,8",
    "FIELD2": "10",
    "FIELD3": "8,19",
    "FIELD4": "10,93",
    "FIELD5": "114,5",
    "FIELD6": "25,8",
    "FIELD7": "32,2",
    "FIELD8": "114,9",
    "FIELD9": "28,5",
    "FIELD10": "33,1"
  },
  {
    "FIELD1": "H75-750-0,9",
    "FIELD2": "11,3",
    "FIELD3": "9,18",
    "FIELD4": "12,23",
    "FIELD5": "129,6",
    "FIELD6": "30,2",
    "FIELD7": "37,6",
    "FIELD8": "129,6",
    "FIELD9": "31,6",
    "FIELD10": "38"
  },
  {
    "FIELD1": "H114-600-0,8",
    "FIELD2": "10",
    "FIELD3": "8,19",
    "FIELD4": "13,66",
    "FIELD5": "320,9",
    "FIELD6": "53,3",
    "FIELD7": "59,7",
    "FIELD8": "320,9",
    "FIELD9": "52,4",
    "FIELD10": "55,8"
  },
  {
    "FIELD1": "H114-600-0,9",
    "FIELD2": "11,3",
    "FIELD3": "9,18",
    "FIELD4": "15,29",
    "FIELD5": "361",
    "FIELD6": "60",
    "FIELD7": "67,2",
    "FIELD8": "361",
    "FIELD9": "59,6",
    "FIELD10": "65,9"
  },
  {
    "FIELD1": "H114-600-1,0",
    "FIELD2": "12,5",
    "FIELD3": "10,16",
    "FIELD4": "16,92",
    "FIELD5": "405,4",
    "FIELD6": "67,6",
    "FIELD7": "75",
    "FIELD8": "405,4",
    "FIELD9": "67,6",
    "FIELD10": "75"
  },
  {
    "FIELD1": "H114-600-1,2",
    "FIELD2": "",
    "FIELD3": "12,12",
    "FIELD4": "20,2",
    "FIELD5": "486,5",
    "FIELD6": "81,1",
    "FIELD7": "90",
    "FIELD8": "486,5",
    "FIELD9": "81,1",
    "FIELD10": "90"
  },
  {
    "FIELD1": "H114-600-1,5",
    "FIELD2": "",
    "FIELD3": "15,06",
    "FIELD4": "25,1",
    "FIELD5": "608,1",
    "FIELD6": "101,4",
    "FIELD7": "112,5",
    "FIELD8": "608,1",
    "FIELD9": "101,4",
    "FIELD10": "112,5"
  },
  {
    "FIELD1": "H114-750-0,8",
    "FIELD2": "11,2",
    "FIELD3": "9,4",
    "FIELD4": "12,5",
    "FIELD5": "307,9",
    "FIELD6": "51,2",
    "FIELD7": "57,1",
    "FIELD8": "307,9",
    "FIELD9": "51,2",
    "FIELD10": "57,1"
  },
  {
    "FIELD1": "H114-750-0,9",
    "FIELD2": "12,6",
    "FIELD3": "10,5",
    "FIELD4": "14",
    "FIELD5": "345,2",
    "FIELD6": "57,4",
    "FIELD7": "64",
    "FIELD8": "345,2",
    "FIELD9": "57,4",
    "FIELD10": "64"
  },
  {
    "FIELD1": "H114-750-1,0",
    "FIELD2": "14",
    "FIELD3": "11,7",
    "FIELD4": "15,4",
    "FIELD5": "383,6",
    "FIELD6": "63,8",
    "FIELD7": "71,1",
    "FIELD8": "383,6",
    "FIELD9": "63,8",
    "FIELD10": "71,1"
  }
]
}
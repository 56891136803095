import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { NewsService } from  '../core/service/news.service'
import { data } from 'jquery';
import { News } from '../shared/models/news.model';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-news',
  templateUrl: './news.component.html',
  styleUrls: ['./news.component.scss']
})
export class NewsComponent {

 newsDataCollection:News[] = [];
 loading = false;

  constructor(private newsService: NewsService) { }

  ngOnInit() {
    this.loading = true
    this.newsService.getNews()
      .subscribe((data:any)=>{
        data.forEach(newsDataElement => {
          this.newsDataCollection.push(new News().deserialize(newsDataElement));
          this.loading = false
        });
      });

  }


}

import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-sibalux',
  templateUrl: './sibalux.component.html',
  styleUrls: ['./sibalux.component.scss']
})
export class SibaluxComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}

<div class="container"> <br>

  <div style="text-align: center;">
    <div *ngIf="!loading; else loadingBlock">
      <div class="huge-margin-top column has-text-centered">
        <div class="img-row" *ngFor="let image of _albums; let i=index">
          <img class="img-frame" [src]="image.thumb" [alt]="image.alt" [title]="image.alt" (click)="open(i)" />
        </div>
      </div>
    </div>
    <ng-template #loadingBlock>
      <div class="text-center">
        <div class="spinner-border text-info" role="status">
          <span class="sr-only">Loading...</span>
        </div>
      </div>
    </ng-template>
  </div>
</div><br><br>
export const StyleColors = {
    colorsArray:["#ee7e1a99",
                 "#fedf61a3",
                 "#eb616c9e",
                 "#be451f96",
                 "#be451f9c",
                 "#0095db96",
                 "#ee7e1a99",
                 "#fedf61a3",
                 "#eb616c9e",
                 "#be451f96",
                 "#be451f9c",
                 "#0070b89c",
                 "#eb616c9e",
                 "#85c4eb9e",
                 "#2d61ab99",
                 "#ee7e1a99",
                 "#fedf61a3",
                 "#eb616c9e",
                 "#be451f96",
                 "#eb616c9e",
                 "#be451f9c",
                 "#4595d18f",
                 "#ee7e1a99",
                 "#fedf61a3",
                 "#eb616c9e",
                 "#be451f96",
                 "#be451f9c",
                 
]
                };
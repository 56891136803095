<div class="container px-0">

  <div *ngIf="isDataLoaded">
    <ngu-carousel #myCarousel [inputs]="carouselConfig" [dataSource]="_items">
      <div *nguCarouselDef="let item of _items; let i=index" class="item">


        <div class="card rounded-0 border border-light">
          <img class="img-frame" [src]="item.thumb" [alt]="item.alt" [title]="item.alt" (click)="open(i)"
            style="height: 220px;" />
          <div class="card-img-overlay p-0" [style.background-color]="item.textBgColor" style="top:auto; ">
            <div class="card-body pt-1 cardBodyObjects">
              <p class="card-text">{{item.alt |slice:0:48}} </p>
            </div>

          </div>
        </div>

      </div>

      <button NguCarouselPrev class="leftRs" [style.opacity]="myCarousel.isFirst ? 0.5:1">&lt;</button>
      <button NguCarouselNext class="rightRs" [style.opacity]="myCarousel.isLast ? 0.5:1">&gt;</button>

    </ngu-carousel>
  </div>
  <div class="d-flex flex-row-reverse bd-highlight"><a routerLink="/objects">Все объекты</a></div>
</div>
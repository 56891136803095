import { NgModule, Component } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './home/home.component';
import { AboutComponent } from './about/about.component';
import { ContactsComponent } from './contacts/contacts.component';
import { CorrugatedBoardComponent } from './production/corrugated-board/corrugated-board.component';
import { TechnicalDocumentationComponent } from './technical-documentation/technical-documentation.component';
import { CertificatesComponent } from './certificates/certificates.component';
import { PirPanelsComponent } from './production/pir-panels/pir-panels.component';
import { TeplantDuetComponent } from './production/teplant-duet/teplant-duet.component';
import { StenoviePaneliComponent } from './production/stenovie-paneli/stenovie-paneli.component';
import { NewsComponent } from './news/news.component';
import { ObjectsComponent } from './objects/objects.component';
import { KrovelniePaneliComponent } from './production/krovelnie-paneli/krovelnie-paneli.component';

import { HarpoonComponent } from './production/harpoon/harpoon.component';
import { FasonComponent } from './production/fason/fason.component';
import { NewsDetailsComponent } from './news-details/news-details.component';
import { SibaluxComponent } from './production/sibalux/sibalux.component';


const routes: Routes = [
  
  {path: '', component: HomeComponent, data: {title: 'Сэндвич-панели в Казани'} },
  {path: 'about', component: AboutComponent, data: {title: 'О компании'}},
  {path: 'contacts', component: ContactsComponent, data: {title: 'Контакты'}},
  {path: 'production/corrugated-board', component: CorrugatedBoardComponent, data: {title: 'Профнастил'}},
  {path: 'technical-documentation', component: TechnicalDocumentationComponent,data: {title: 'Техническая документация'}},
  {path: 'certificates', component: CertificatesComponent,data: {title: 'Сертификаты'}},
  {path: 'production/pir-panels', component: PirPanelsComponent,data: {title: 'Сэндвич-панели с пенополиизоциануратом'}},
  {path: 'production/teplant-duet', component: TeplantDuetComponent,data: {title: 'Навесная фасадная система Теплант-Дуэт'}},
  {path: 'production/stenovie-paneli', component: StenoviePaneliComponent,data: {title: 'Стеновые сэндвич-панели'}},
  {path: 'news', component: NewsComponent,data: {title: 'Новости'}},
  {path: 'objects', component: ObjectsComponent,data: {title: 'Объекты'}},
  {path: 'production/krovelnie-paneli', component: KrovelniePaneliComponent,data: {title: 'Кровельные сэндвич-панели'}},
  {path: 'production/harpoon', component: HarpoonComponent,data: {title: 'Крепеж'}},
  {path: 'production/fason', component: FasonComponent,data: {title: 'Фасонные элементы'}},
  {path: 'news-details/:id', component: NewsDetailsComponent,data: {title: 'Новости'}},
  {path: 'production/sibalux', component: SibaluxComponent, data: {title: 'Композитные панели Sibalux'}}
   
];

@NgModule({
  //imports: [RouterModule.forRoot(routes)],
  imports: [
    RouterModule.forRoot(routes, {
      anchorScrolling: 'enabled',
    })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }

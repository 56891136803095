<div class="container">
  <br>
  <h3>Контактная информация ООО «Специалист - Казань»</h3><br>
  <div class="card mb-3" style="max-width: 900px;">
    <div class="row no-gutters">
      <div class="col-md-8">
        <div class="map-responsive">
          <iframe class="aside"
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1120.5441722341477!2d49.118942468097586!3d55.826425874858614!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x415952ab3c061171%3A0x6dfbfb0cda0b4b44!2z0L_RgC3Rgi4g0KXRg9GB0LDQuNC90LAg0K_QvNCw0YjQtdCy0LAsIDQ10LAsINCa0LDQt9Cw0L3RjCwg0KDQtdGB0L8uINCi0LDRgtCw0YDRgdGC0LDQvSwgNDIwMTI0!5e0!3m2!1sru!2sru!4v1583151209130!5m2!1sru!2sru"
            width="600" height="450" frameborder="0" style="border:0;" allowfullscreen=""></iframe>
        </div>
      </div>
      <div class="col-md-4">
        <div class="card-body">
          <h5 class="card-title">Адрес:</h5>
          <p class="card-text">Россия, 420124, Казань,<br> пр-т. Хусаина Ямашева 45а, оф 501</p>
          <h5 class="card-title font-weight-bold">Казань:</h5>
          <p class="card-text"><a href="tel:+78435282701">+7 (843) 528-27-01</a></p>
          <p class="card-text"> <a href="tel:+78435282703">+7 (843) 528-27-03</a> (факс)<br> </p>
          <p class="card-text"><a href="mailto:specialist_kazan@rambler.ru" class="email">office@specialist-kazan.ru
            </a> </p>

          <h5 class="card-title font-weight-bold">Набережные Челны: <br></h5>
          <a href="tel:+79874159745">+7 (987) 415-97-45 </a>
          <p></p>

        </div>
      </div>
    </div>

  </div>
  <br> <br>

</div>
<br> <br>
import { Component, OnInit,Input } from '@angular/core';
import { ActivatedRoute,Router, Params } from '@angular/router';
import { NewsService } from '../core/service/news.service';
import { News } from '../shared/models/news.model';
import { param } from 'jquery';

@Component({
  selector: 'app-news-details',
  templateUrl: './news-details.component.html',
  styleUrls: ['./news-details.component.scss']
})
export class NewsDetailsComponent implements OnInit {

  newsDataCollection:News[] = [];

  newsId:string;

  newsDataId: any;

     constructor(
      private route:Router,
       private activatedRoute:ActivatedRoute,
       private newsService:NewsService) {
     }


  ngOnInit(): void {
    this.activatedRoute.params.subscribe(params => {
      this.newsId = params['id'];
  });

    this.newsService.getNewsByID(this.newsId)
    .subscribe(response => 
      {console.log('Response', response)
      this.newsDataId = response;
  })
      
    
  }
    
  

}

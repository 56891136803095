import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-teplant-duet',
  templateUrl: './teplant-duet.component.html',
  styleUrls: ['./teplant-duet.component.scss']
})
export class TeplantDuetComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}

import { Component, OnInit } from '@angular/core';


@Component({
  selector: 'app-technical-documentation',
  templateUrl: './technical-documentation.component.html',
  styleUrls: ['./technical-documentation.component.scss']
})
export class TechnicalDocumentationComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}

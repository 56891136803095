<div class="container p-0">

 

    <div id="carouselExampleControls" class="carousel slider" data-ride="carousel">
        <div class="carousel-inner">
          <div class="carousel-item active">
            <img class="d-block w-100" src="/assets/images/carousel/agropark.jpg" alt="">
            <div class="carousel-caption d-none d-md-block">
                <h2>РАБОТАЙТЕ  СО <br>СПЕЦИАЛИСТАМИ</h2>
                <h5>Лучшие сэндвич панели Поволжья</h5>
              </div>
          </div>
          <div class="carousel-item">
            <img class="d-block w-100" src="/assets/images/carousel/fima_dvorec.jpg" alt="">
                <div class="carousel-caption d-none d-md-block">
                  <h2>РАБОТАЙТЕ СО <br>СПЕЦИАЛИСТАМИ</h2>
                  <h5>Лучшие сэндвич панели Поволжья</h5>
                </div>
              
          </div>
          <div class="carousel-item">
            <img class="d-block w-100" src="/assets/images/carousel/megastroy.jpg" alt="">
            <div class="carousel-caption d-none d-md-block">
                <h2>РАБОТАЙТЕ СО <br>СПЕЦИАЛИСТАМИ</h2>
                <h5>Лучшие сэндвич панели Поволжья</h5>
              </div>
          </div>
          <div class="carousel-item">
            <img class="d-block w-100" src="/assets/images/carousel/selgross.jpg" alt="">
            <div class="carousel-caption d-none d-md-block">
                <h2>РАБОТАЙТЕ СО <br>СПЕЦИАЛИСТАМИ</h2>
                <h5>Лучшие сэндвич панели Поволжья</h5>
              </div>
          </div>
          <div class="carousel-item">
            <img class="d-block w-100" src="/assets/images/carousel/sklad.jpg" alt="">
            <div class="carousel-caption d-none d-md-block">
                <h2>РАБОТАЙТЕ СО <br>СПЕЦИАЛИСТАМИ</h2>
                <h5>Лучшие сэндвич панели Поволжья</h5>
              </div>
          </div>
        </div>
        <a class="carousel-control-prev" href="#carouselExampleControls" role="button" data-slide="prev">
          <span class="carousel-control-prev-icon" aria-hidden="true"></span>
          <span class="sr-only">Previous</span>
        </a>
        <a class="carousel-control-next" href="#carouselExampleControls" role="button" data-slide="next">
          <span class="carousel-control-next-icon" aria-hidden="true"></span>
          <span class="sr-only">Next</span>
        </a>
      </div>

      <span style="text-align: center;"><br><h3>ООО «СПЕЦИАЛИСТ-КАЗАНЬ» —<br> ОФИЦИАЛЬНЫЙ ДИЛЕР ООО «ЭЛЕКТРОЩИТ-СТРОЙСИСТЕМА»</h3><br></span>
      <div class="row">
        <div class="col-9">
          <p>20 лет наша компания комплектует строительные объекты сэндвич-панелями Teplant.</p>
      <p>Сегодня ООО «Специалист-Казань» занимает лидирующие позиции среди компаний-партнёров и является официальным дилером 
        по Республике Татарстан ООО «Электрощит-Стройсистема» - производителя сэндвич-панелей Teplant.</p>
      <p>ООО «Электрощит-Стройсистема» входит в производственную группу «Стройсистема» и занимается производством металлоконструкций, металлочерепицы,
         профнастила, сэндвич-панелей, быстровозводимых зданий, блок-контейнеров.</p>
         
        </div>
        <div class="col-3">
          <a
        href="/assets/files/Каталог_технических_решений.pdf" target="_blank"><img id="" src="/assets/images/Каталог_технических_решений copy.jpg" ></a>
          
        </div>
      </div>
      
      
         
      
         <br><h4>ФИЛЬМ О КОМПАНИИ</h4><br>
         <div class="videoBorder embed-responsive embed-responsive-16by9" style="width: 1140px; height: 450px;">
          <iframe id="player" frameborder="0" allowfullscreen="1" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" title="YouTube video player" 
          width="640" height="360" src="https://www.youtube.com/embed/aBeTLa1aCeM?enablejsapi=1&amp;origin=http%3A%2F%2Fspecialist-kazan.ru&amp;widgetid=1"></iframe>         
        </div>  
        <br>

         <div class="videoBorder embed-responsive embed-responsive-16by9" style="width: 1140px; height: 450px;">
          <iframe id="player" frameborder="0" allowfullscreen="1" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" title="YouTube video player" 
          width="640" height="360" src="https://www.youtube.com/embed/daFKfCWoQC8?enablejsapi=1&amp;origin=http%3A%2F%2Fspecialist-kazan.ru&amp;widgetid=1"></iframe>         
        </div>  
        <br>
        
<br><h4>НАШИ ОБЪЕКТЫ</h4>  
<app-objects-carousel></app-objects-carousel>
<br><h4>НОВОСТИ</h4>
<app-news-carousel></app-news-carousel>
<br><h4>НАГРАДЫ</h4> 
<app-awards-carousel></app-awards-carousel>
<br><br></div>

<div class="container">
  <br>
  <div class="document-box">
    <ul class="document-list">
      <h3>Технический каталог</h3>
      <span class="mr-3" style="font-size: 30px; color: #ea5e1f;"><i class="far fa-file-pdf"></i></span><a
        href="/assets/files/Расчетные нагрузки Теплант Концепт.pdf" target="_blank">Расчетные нагрузки Teplant (Теплант)
        Concept</a> ( 0.60 Mб ) <br>
      <span class="mr-3" style="font-size: 30px; color: #ea5e1f;"><i class="far fa-file-pdf"></i></span><a
        href="/assets/files/Расчетные нагрузки Теплант Универсал.pdf" target="_blank">Расчетные нагрузки Teplant
        (Теплант) Universal</a> ( 0.47 Mб)<br>
      <span class="mr-3" style="font-size: 30px; color: #ea5e1f;"><i class="far fa-file-pdf"></i></span><a
        href="/assets/files/Инструкция по монтажу.pdf" target="_blank">Инструкция по монтажу </a> ( 1.49 Mб)<br>
      <span class="mr-3" style="font-size: 30px; color: #ea5e1f;"><i class="far fa-file-pdf"></i></span><a
        href="/assets/files/Каталог_технических_решений.pdf" target="_blank">Конструктивные решения Teplant</a> ( 14.20
      Mб)<br>
      <span class="mr-3" style="font-size: 30px; color: #ea5e1f;"><i class="far fa-file-pdf"></i></span><a
        href="/assets/files/Инструкция по транспортированию.pdf" target="_blank">Инструкция по транспортировке и
        складированию пакетов конструкционных сэндвич-панелей</a> ( 8.10 Mб )<br>
    </ul>
    <br>
    <br>

  </div>
  <br><br>
</div>
<div class="container">
  <br>
  <h4>СЭНДВИЧ-ПАНЕЛИ С ПЕНОПОЛИИЗОЦИАНУРАТОМ</h4><br>
  <p>Сэндвич-панели с пенополиизоциануратом представляют собой легкую трехслойную ограждающую конструкцию из двух
    стальных профилированных листов, окрашенных или оцинкованных, и среднего слоя утеплителя. Пенополиизоцианурат (ПИР)
    является модифицированным пенополиуретаном с добавлением антипереновых добавок. Как и ППУ, ПИР получается в
    результате реакции полиола и изоцианурата. Благодаря такой химической "преемственности", пенополиизоцианурат
    сохраняет все положительные свойства полиуретана: низкую теплопроводность, малую плотность, хороший предел прочности
    при сжатии, паро- и влагонепроницаемость, долговечность. Кроме того, по сравнению с ППУ пенополиизоцианурат обладает
    повышенной огнестойкостью и более устойчив к воздействию вредных веществ и солнечного излучения.</p>
  <p>Сэндвич-панели с утеплителем из пенополиизоцианурата (ПИР) могут быть использованы во всех регионах РФ при
    температуре наружной поверхности панели от - 65 до + 85 ºС, температуре внутренней поверхности панели до + 30ºС,
    относительной влажности воздуха внутри помещений не более 60 % с допустимыми нормативными значениями ветровой и
    снеговой нагрузки при неагрессивной, слабоагрессивной и среднеагрессивной среде.</p>
  <h5>Физико-технические характеристики ПИР панелей:</h5> <br>
  <table class="table table-bordered">
    <thead class="table-info">
      <tr>
        <th scope="col">Наименование показателя</th>
        <th scope="col">Значение параметра</th>
      </tr>
    </thead>
    <tbody>
      <tr>
        <td>Объемная масса, кг/м3, не более</td>
        <td>40-42</td>
      </tr>
      <tr>
        <td>Толщина панелей, мм</td>
        <td>30-250</td>
      </tr>
      <tr>
        <td>Длина панелей, мм</td>
        <td>До 14 000</td>
      </tr>
      <tr>
        <td>Коэффициент теплопроводности, Вт/м2 °К, не более</td>
        <td>0,018</td>
      </tr>
      <tr>
        <td>Водопоглощение за 24ч при насыщении водой, %, не более</td>
        <td>2,0</td>
      </tr>
      <tr>
        <td>Индекс изоляции воздушного шума, дБ</td>
        <td>до 35</td>
      </tr>
      <tr>
        <td>Группа горючести</td>
        <td>Г1</td>
      </tr>
      <tr>
        <td>Огнестойкость стеновых панелей</td>
        <td>до EI 60</td>
      </tr>
      <tr>
        <td>Огнестойкость кровельных панелей</td>
        <td>до RE 30</td>
      </tr>
    </tbody>
  </table>
  <br>
  <p>Из приведенной таблицы видно, что сэндвич-панели с утеплителями из пенополиизоцианурата (PIR) легче, прочнее и
    теплее сэндвич-панелей с другими теплоизоляционными материалами.</p>
  <br>
  <dl class="faq">

    <dt> Низкая теплопроводность и вес </dt>
    <dd>позволяют использовать сэндвич-панели меньшей толщины, делать облегченный фундамент и металлоконструкции</dd>

    <dt>Малая плотность</dt>
    <dd> и отсутствие мостиков холода дает высокую степень комфортности в любом климате</dd>

    <dt>Устойчив к влаге </dt>
    <dd>и агрессивным (кислотным и щелочным) средам</dd>

    <dt>Более высокие прочностные характеристики</dt>
    <dd>за счет высокой адгезии к облицовкам и более жесткой структуры пены</dd>

    <dt>Является биологически нейтральным материалом</dt>
    <dd>устойчивым к микроорганизмам, гниению, воздействию грызунов, не оказывает влияние на физиологию человека</dd>

    <dt>Закрытопористая мелкоячеистая структура утеплителя не поддерживает горение</dt>
    <dd>

    </dd>
    <dt>Возможность демонтажа и перевозки в другое место</dt>
    <dd>

    </dd>
    <dt>Долговечность</dt>
    <dd>
    </dd>
  </dl>
  <br>

  <h5>Существуют несколько видов замкового соединения:</h5>
  <div>
    <div class="imageWrapper">
      <img src="/assets/images/production/pir1.jpg">
      <a href="" class="cornerLink">Одинарный шип-паз</a>
    </div>

    <div class="imageWrapper">
      <img src="/assets/images/production/pir2.jpg">
      <a href="" class="cornerLink">Двойной шип-паз</a>
    </div>
  </div> <br>


  <p>Данные типы соединений позволяют среднему слою выполнять роль утеплителя, антиконденсатного и шумоизолирующего
    материала.</p>
  <p> Благодаря своим уникальным химическим свойствам ПИР сэндвич-панели незаменимы при строительстве: морозильных
    камер; складов вредных или химически активных веществ; хранилищ жидкого газа;</p>
  <p>Также в зависимости от требований пожарной безопасности сэндвич панели из пенополиизоцианурата используются для
    строительства: крупных холодильных и морозильных складов; производственных, складских и логистических комплексов;
    торговых комплексов; спортивных сооружений, бассейнов, кинотеатров; объектов коммерческой недвижимости; объектов
    пищевой промышленности. Присвоенный класс горючести Г1.</p>

  <br><br>
</div>
<div class="container">
    <br>
    <h4> Алюминиевые и стальные композитные панели SIBALUX</h4> <br>
    <p>Алюминиевые композитные панели марки SIBALUX изготавливаются из высококачественных компонентов с использованием
        передовых технологий и представляют собой конструкцию, состоящую из двух алюминиевых листов и огнеупорной
        вставки. Панели SIBALUX обладает совокупностью ряда качеств: легкостью, прочностью, жесткостью, устойчивостью к
        коррозии, а также звуко- и термоизоляционными свойствами. При этом уникальный состав вставки обеспечивает
        сочетание эластичности с высокой степенью огнестойкости материалов, что позволяет использовать их на зданиях
        любого назначения, конфигурации и высотности с соблюдением всех норм пожарной безопасности, а также на
        обрамлении оконных проемов.</p>

    <p>При разработке продукции SIBALUX особое внимание уделялось вопросу их огнестойкости. В результате удалось
        разработать специальный состав для огнестойкого сердечника, представляющий собой композицию на основе полимера,
        гидроокиси магния и других антипиренов. Одним из основных пунктов технической оценки панелей SIBALUX РФ и
        SIBALUX РФ ПЛЮС являются так называемые «четыре единицы» - Г1, В1, Д1, Т1.</p>

    <p> Немаловажно, что высокая эластичность панелей марки SIBALUX упрощает процесс обработки и позволяет придавать им
        любую архитектурную форму непосредственно на месте монтажа. Материал поддается обработке любым известным
        способом (распиливание, фрезерование, рубка, штамповка, гибка, вальцовка, сверление…) при помощи специальных
        инструментов для композитных панелей.</p>

    <p>Остановив свой выбор на продукции SIBALUX, вы получаете в свое распоряжение:</p>

    <ul>
        <li>современные универсальные фасадные материалы, обладающие повышенной огнестойкостью, способные
            трансформироваться
            в любые смелые архитектурные формы, с неограниченным диапазоном применения, который охватывает как новое
            строительство - промышленное и гражданское, так и реконструкцию зданий и сооружений; </li>
        <li>широкую гамму цветовых, декоративных, фактурных решений, в том числе декоративные поверхности с имитацией
            дерева
            и камня, 3D-эффектом, «хамелеоны » и «спарклинги», шабрированные и зеркальные поверхности, а также
            возможность
            производства покрытия по индивидуальному запросу, благодаря наличию современной окрашивающей линии;</li>
        <li>стабильно высокое качество материала, тотально контролируемое в собственной лаборатории;</li>
        <li>сналичие всех разрешительных документов, которые точно соответствуют продаваемой продукции;</li>
        <li>гарантированные сроки поставки с учетом продолжительности строительного цикла.</li>
    </ul><br><br>

    <div class="container">
        <div class="row row-cols-2">
            <div class="col border" style="max-width: 400px">
                <div>
                <h5>СИБАЛЮКС РФ</h5>
                <p>Композитная панель СИБАЛЮКС РФ состоит из двух алюминиевых листов и минеральной огнестойкой
                    прослойки. Панели обладают совокупностью ряда качеств: легкостью, прочностью, жесткостью,
                    устойчивостью
                    к коррозии, а также звуко- и термоизоляционными свойствами.</p>
                </div>
            </div>
            <div class="col border"><img src="/assets/images/Sibalux/SIBALUX_0002.jpg" class="img-fluid"></div>
            <div class="col border" style="max-width: 400px">
                <h5>СИБАЛЮКС РФ ПЛЮС</h5>
                <p>СИБАЛЮКС РФ ПЛЮС - это сочетание эластичнос-ти с высокой степенью огнестойкости, что позволя-ет
                    использовать панели на фасадах любого назна-чения, конфигурации и высотности с соблюдением всех норм
                    пожарной безопасности. </p>
            </div>
            <div class="col border"><img src="/assets/images/Sibalux/SIBALUX_0003.jpg" class="img-fluid"></div>
            <div class="col border" style="max-width: 400px">
                <h5>SBL A2 </h5>
                <p>SBL А2 негорючий материал, отвечающий самым высоким требованиям пожарной безопасности. Состоит из
                    двух алюминиевых листов и минеральной огнестойкой прослойки. </p>
            </div>
            <div class="col border"><img src="/assets/images/Sibalux/SIBALUX_0004.jpg" class="img-fluid"></div>
            <div class="col border" style="max-width: 400px">
                <h5>СИБАЛЮКС СТАЛЬ</h5>
                <p>Композитная панель СИБАЛЮКС СТАЛЬ состоит из двух стальных листов и минеральной огнестойкой
                    прослойки. Поставляется в двух вариантах: в окрашенной стали и нержавеющей стали. </p>
            </div>
            <div class="col border"><img src="/assets/images/Sibalux/SIBALUX_0005.jpg" class="img-fluid"></div>
            <div class="col border" style="max-width: 400px">
                <h5>СИБАЛЮКС СТАЛЬ А2</h5>
                <p>Негорючий материал, отвечающий самым высоким требованиям пожарной безопасности, состоит из двух
                    стальных листов и минеральной огнестойкой прослойки. </p>
            </div>
            <div class="col border"><img src="/assets/images/Sibalux/SIBALUX_0006.jpg" class="img-fluid"></div>
        </div>
    </div>
    <br>


    <h5>КАТАЛОГ ЦВЕТОВ</h5><br>
    <p>В руках архитектора строительство становится настоящим искусством…», — в этих словах выражена сама суть
        архитектурного проектирования. Главные факторы архитектурной выразительности, которыми пользуется архитектор, —
        это объем, форма, цвет, фактура поверхности.</p>

    <p> SIBALUX открывает перед ним неограниченный диапазон возможностей, предлагая широкую палитру красок и фактур:
        «металлики», «хамелеоны», «sparkling», монохромные цвета (стандартные и по каталогам RAL, RAL Design, NCS),
        глянцевые и матовые цвета, 3D-эффект, имитация дерева и камня, шабрированные и зеркальные поверхности... Наличие
        современной линии по окрашиванию алюминия дает простор для любых стилистических и колористических решений.</p>

    <h6>Wood</h6>
    <div class="text-center" style="font-weight: 300; font-size: 12px;">
        <div class="text-center" style="font-weight: 300; font-size: 12px;">

            <div class="row" style="margin-left: 0; margin-right: 0;">
                <div class="col-xs-3 col-md-1 text-center"
                    style="padding: 5px; background-image: url(/assets/images/SibaluxColors/SL-052_тёмный_дуб.jpg); height: 65px;">
                    <span style="color: #ffffff;">SL-052<br>
                        Темный дуб</span>
                </div>
                <div class="col-xs-3 col-md-1 text-center"
                    style="padding: 5px; background-image: url(/assets/images/SibaluxColors/SL-055-2_тёмный_тик.jpg); height: 65px;">
                    <span style="color: #ffffff;">SL-055/2<br>
                        Темный тик</span>
                </div>
                <div class="col-xs-3 col-md-1 text-center"
                    style="padding: 5px; background-image: url(/assets/images/SibaluxColors/SL-050_марокканский_орех.jpg); height: 65px;">
                    <span style="color: #ffffff;">SL-050<br>
                        Марокканский орех</span>
                </div>
                <div class="col-xs-3 col-md-1 text-center"
                    style="padding: 5px;  background-image: url(/assets/images/SibaluxColors/SL-185_дуб.jpg); height: 65px;">
                    <span style="color: #ffffff;">SL-185<br>
                        Дуб</span>
                </div>
                <div class="col-xs-3 col-md-1 text-center"
                    style="padding: 5px; background-image: url(/assets/images/SibaluxColors/SL-162_лиственница.jpg); height: 65px;">
                    <span style="color: #ffffff;">SL-162<br>
                        Лиственница</span>
                </div>
                <div class="col-xs-3 col-md-1 text-center"
                    style="padding: 5px; background-image: url(/assets/images/SibaluxColors/SL-053_тёмный_фактурный_дуб.jpg); height: 65px;">
                    <span style="color: #ffffff;">SL-053<br>
                        Тёмный фактурный дуб</span>
                </div>
                <div class="col-xs-3 col-md-1 text-center"
                    style="padding: 5px; background-image: url(/assets/images/SibaluxColors/SL-176_золотистый_ясень.jpg); height: 65px;">
                    <span style="color: #ffffff;">SL-176 <br>
                        Золотистый ясень</span>
                </div>
                <div class="col-xs-3 col-md-1 text-center"
                    style="padding: 5px; background-image: url(/assets/images/SibaluxColors/SL-169_античная_сосна_brosko.jpg); height: 65px;">
                    <span style="color: #ffffff;">SL-169 <br>
                        Античная сосна (brosko)</span>
                </div>
                <div class="col-xs-3 col-md-1 text-center"
                    style="padding: 5px; background-image: url(/assets/images/SibaluxColors/SL-182_американский_орех.jpg); height: 65px;">
                    <span style="color: #ffffff;">SL-182 <br>
                        Американский орех</span>
                </div>
                <div class="col-xs-3 col-md-1 text-center"
                    style="padding: 5px; background-image: url(/assets/images/SibaluxColors/SL-163_ясень.jpg); height: 65px;">
                    <span style="color: #ffffff;">SL-163 <br>
                        Ясень</span>
                </div>
                <div class="col-xs-3 col-md-1 text-center"
                    style="padding: 5px; background-image: url(/assets/images/SibaluxColors/SL-152_рябина.jpg); height: 65px;">
                    <span style="color: #ffffff;">SL-152 <br>
                        Рябина</span>
                </div>
                <div class="col-xs-3 col-md-1 text-center"
                    style="padding: 5px; background-image: url(/assets/images/SibaluxColors/SL-179_выбеленная_сосна.jpg); height: 65px;">
                    <span style="color: #000000;">SL-179 <br>
                        Выбеленная сосна</span>
                </div>
            </div> <br>

            <h6>Аnodized</h6>
            <div class="row" style="margin-left: 0; margin-right: 0;">
                <div class="col-xs-3 col-md-1 text-center"
                    style="padding: 5px; background-image: url(/assets/images/SibaluxColors/sl-121_античная_бронза.jpg); height: 65px;">
                    <span style="color: #ffffff;">SL-121<br>
                        Античная бронза</span>
                </div>
                <div class="col-xs-3 col-md-1 text-center"
                    style="padding: 5px; background-image: url(/assets/images/SibaluxColors/SL-120_shampagne.jpg); height: 65px;">
                    <span style="color: #ffffff;">SL-120<br>
                        Shampagne</span>
                </div>
                <div class="col-xs-3 col-md-1 text-center"
                    style="padding: 5px; background-image: url(/assets/images/SibaluxColors/sl-105_red_gold.jpg); height: 65px;">
                    <span style="color: #ffffff;">SL-105<br>
                        Red gold</span>
                </div>
                <div class="col-xs-3 col-md-1 text-center"
                    style="padding: 5px;  background-image: url(/assets/images/SibaluxColors/sl-122_тоффи.jpg); height: 65px;">
                    <span style="color: #ffffff;">SL-122<br>
                        Тоффи</span>
                </div>
                <div class="col-xs-3 col-md-1 text-center"
                    style="padding: 5px;  background-image: url(/assets/images/SibaluxColors/sl-123-black.jpg); height: 65px;">
                    <span style="color: #ffffff;">SL-123<br>
                        Black</span>
                </div>
                <div class="col-xs-3 col-md-1 text-center"
                    style="padding: 5px;  background-image: url(/assets/images/SibaluxColors/sl-125_античная_медь.jpg); height: 65px;">
                    <span style="color: #ffffff;">SL-125<br>
                        Античная медь</span>
                </div>
            </div><br>

            <h6>Metallic</h6>
            <div class="row" style="margin-left: 0; margin-right: 0;">
                <div class="col-xs-3 col-md-1 text-center"
                    style="padding: 5px; background-image: url(/assets/images/SibaluxColors/sl-001_серебро_металлик.jpg); height: 65px;">
                    <span style="color: #ffffff;">SL-001<br>
                        Cеребро металлик</span>
                </div>
                <div class="col-xs-3 col-md-1 text-center"
                    style="padding: 5px; background-image: url(/assets/images/SibaluxColors/sl-090_сталь.jpg); height: 65px;">
                    <span style="color: #ffffff;">SL-090<br>
                        Сталь</span>
                </div>
                <div class="col-xs-3 col-md-1 text-center"
                    style="padding: 5px; background-image: url(/assets/images/SibaluxColors/sl-091_золотистая_шампань.jpg); height: 65px;">
                    <span style="color: #ffffff;">SL-091<br>
                        Золотистая шампань</span>
                </div>
                <div class="col-xs-3 col-md-1 text-center"
                    style="padding: 5px;  background-image: url(/assets/images/SibaluxColors/sl-005_бронза.jpg); height: 65px;">
                    <span style="color: #ffffff;">SL-005<br>
                        Бронза</span>
                </div>
                <div class="col-xs-3 col-md-1 text-center"
                    style="padding: 5px;  background-image: url(/assets/images/SibaluxColors/sl-123-black.jpg); height: 65px;">
                    <span style="color: #ffffff;">SL-092<br>
                        Солнечное золото</span>
                </div>
                <div class="col-xs-3 col-md-1 text-center"
                    style="padding: 5px;  background-image: url(/assets/images/SibaluxColors/sl-007_голубой_металлик.jpg); height: 65px;">
                    <span style="color: #ffffff;">SL-007<br>
                        Голубой металлик</span>
                </div>
                <div class="col-xs-3 col-md-1 text-center"
                    style="padding: 5px;  background-image: url(/assets/images/SibaluxColors/sl-040_терракотовый_металлик.jpg); height: 65px;">
                    <span style="color: #ffffff;">SL-040<br>
                        Терракотовый металлик</span>
                </div>
                <div class="col-xs-3 col-md-1 text-center"
                    style="padding: 5px;  background-image: url(/assets/images/SibaluxColors/sl-042_матовый_графит.jpg); height: 65px;">
                    <span style="color: #ffffff;">SL-042<br>
                        Матовый графит</span>
                </div>
                <div class="col-xs-3 col-md-1 text-center"
                    style="padding: 5px;  background-image: url(/assets/images/SibaluxColors/sl-002_искристое_серебро.jpg); height: 65px;">
                    <span style="color: #ffffff;">SL-002<br>
                        Искристое серебро</span>
                </div>
                <div class="col-xs-3 col-md-1 text-center"
                    style="padding: 5px;  background-image: url(/assets/images/SibaluxColors/sl-004_золото.jpg); height: 65px;">
                    <span style="color: #ffffff;">SL-004<br>
                        Золото</span>
                </div>
                <div class="col-xs-3 col-md-1 text-center"
                    style="padding: 5px;  background-image: url(/assets/images/SibaluxColors/sl-003_шампань.jpg); height: 65px;">
                    <span style="color: #ffffff;">SL-003<br>
                        Шампань</span>
                </div>
                <div class="col-xs-3 col-md-1 text-center"
                    style="padding: 5px;  background-image: url(/assets/images/SibaluxColors/sl-006_медь.jpg); height: 65px;">
                    <span style="color: #ffffff;">SL-006<br>
                        Медь</span>
                </div>
                <div class="col-xs-3 col-md-1 text-center"
                    style="padding: 5px;  background-image: url(/assets/images/SibaluxColors/sl-093_аметист.jpg); height: 65px;">
                    <span style="color: #ffffff;">SL-093<br>
                        Аметист</span>
                </div>
                <div class="col-xs-3 col-md-1 text-center"
                    style="padding: 5px;  background-image: url(/assets/images/SibaluxColors/sl-008_зеленый_металлик.jpg); height: 65px;">
                    <span style="color: #ffffff;">SL-008<br>
                        Зеленый металлик</span>
                </div>
                <div class="col-xs-3 col-md-1 text-center"
                    style="padding: 5px;  background-image: url(/assets/images/SibaluxColors/sl-043_golden_brown.jpg); height: 65px;">
                    <span style="color: #ffffff;">SL-043<br>
                        Golden brown</span>
                </div>
            </div> <br>

            <h6>Pearl</h6>
            <div class="row" style="margin-left: 0; margin-right: 0;">
                <div class="col-xs-3 col-md-1 text-center"
                    style="padding: 5px; background-image: url(/assets/images/SibaluxColors/sl-203_розовый_жемчуг.jpg); height: 65px;">
                    <span style="color: #000000;">SL-203<br>
                        Розовый жемчуг</span>
                </div>
                <div class="col-xs-3 col-md-1 text-center"
                    style="padding: 5px; background-image: url(/assets/images/SibaluxColors/sl-204_лиловый_жемчуг.jpg); height: 65px;">
                    <span style="color: #000000;">SL-204<br>
                        Лиловый жемчуг</span>
                </div>
                <div class="col-xs-3 col-md-1 text-center"
                    style="padding: 5px; background-image: url(/assets/images/SibaluxColors/sl-205_голубой_жемчуг.jpg); height: 65px;">
                    <span style="color: #000000;">SL-205<br>
                        Голубой жемчуг</span>
                </div>
            </div> <br>

            <h6>Solid colors</h6>
            <div class="row" style="margin-left: 0; margin-right: 0;">
                <div class="col-xs-3 col-md-1 text-center"
                    style="padding: 5px; background-image: url(/assets/images/SibaluxColors/sl-102_молочный.jpg); height: 65px;">
                    <span style="color: #000000;">SL-102<br>
                        Молочный</span>
                </div>
                <div class="col-xs-3 col-md-1 text-center"
                    style="padding: 5px; background-image: url(/assets/images/SibaluxColors/sl-103_сигнальный_белый.jpg); height: 65px;">
                    <span style="color: #000000;">SL-103<br>
                        Сигнальный белый</span>
                </div>
                <div class="col-xs-3 col-md-1 text-center"
                    style="padding: 5px; background-image: url(/assets/images/SibaluxColors/sl-205_голубой_жемчуг.jpg); height: 65px;">
                    <span style="color: #000000;">SL-045<br>
                        Кремовый</span>
                </div>
                <div class="col-xs-3 col-md-1 text-center"
                    style="padding: 5px; background-image: url(/assets/images/SibaluxColors/sl-094_оранжевый_сигнальный.jpg); height: 65px;">
                    <span style="color: #ffffff;">SL-094<br>
                        Оранжевый сигнальный</span>
                </div>
                <div class="col-xs-3 col-md-1 text-center"
                    style="padding: 5px; background-image: url(/assets/images/SibaluxColors/sl-012_желтый.jpg); height: 65px;">
                    <span style="color: #ffffff;">SL-012<br>
                        Желтый</span>
                </div>
                <div class="col-xs-3 col-md-1 text-center"
                    style="padding: 5px; background-image: url(/assets/images/SibaluxColors/sl-014_зеленый.jpg); height: 65px;">
                    <span style="color: #ffffff;">SL-014<br>
                        Зеленый</span>
                </div>
                <div class="col-xs-3 col-md-1 text-center"
                    style="padding: 5px; background-image: url(/assets/images/SibaluxColors/SL-015_бирюзовый.jpg); height: 65px;">
                    <span style="color: #ffffff;">SL-015<br>
                        Бирюзовый</span>
                </div>
                <div class="col-xs-3 col-md-1 text-center"
                    style="padding: 5px; background-image: url(/assets/images/SibaluxColors/SL-097_голубино-синий.jpg); height: 65px;">
                    <span style="color: #ffffff;">SL-097<br>
                        Голубино-синий</span>
                </div>
                <div class="col-xs-3 col-md-1 text-center"
                    style="padding: 5px; background-image: url(/assets/images/SibaluxColors/SL-017_синий.jpg); height: 65px;">
                    <span style="color: #ffffff;">SL-017<br>
                        Синий</span>
                </div>
                <div class="col-xs-3 col-md-1 text-center"
                    style="padding: 5px; background-image: url(/assets/images/SibaluxColors/SL-010_красный.jpg); height: 65px;">
                    <span style="color: #ffffff;">SL-010<br>
                        Красный</span>
                </div>
                <div class="col-xs-3 col-md-1 text-center"
                    style="padding: 5px; background-image: url(/assets/images/SibaluxColors/SL-107_розовый.jpg); height: 65px;">
                    <span style="color: #ffffff;">SL-107<br>
                        Розовый</span>
                </div>
                <div class="col-xs-3 col-md-1 text-center"
                    style="padding: 5px; background-image: url(/assets/images/SibaluxColors/SL-019_белый.jpg); height: 65px;">
                    <span style="color: #000000;">SL-019<br>
                        Белый</span>
                </div>
                <div class="col-xs-3 col-md-1 text-center"
                    style="padding: 5px; background-image: url(/assets/images/SibaluxColors/SL-020_слоновая_кость.jpg); height: 65px;">
                    <span style="color: #000000;">SL-020<br>
                        Слоновая кость</span>
                </div>
                <div class="col-xs-3 col-md-1 text-center"
                    style="padding: 5px; background-image: url(/assets/images/SibaluxColors/SL-046_бежевый.jpg); height: 65px;">
                    <span style="color: #000000;">SL-046<br>
                        Бежевый</span>
                </div>
                <div class="col-xs-3 col-md-1 text-center"
                    style="padding: 5px; background-image: url(/assets/images/SibaluxColors/SL-011_оранжевый.jpg); height: 65px;">
                    <span style="color: #ffffff;">SL-011<br>
                        Оранжевый</span>
                </div>
                <div class="col-xs-3 col-md-1 text-center"
                    style="padding: 5px; background-image: url(/assets/images/SibaluxColors/SL-013_темно-зеленый.jpg); height: 65px;">
                    <span style="color: #ffffff;">SL-013<br>
                        Темно-зеленый</span>
                </div>
                <div class="col-xs-3 col-md-1 text-center"
                    style="padding: 5px; background-image: url(/assets/images/SibaluxColors/SL-095_салатовый.jpg); height: 65px;">
                    <span style="color: #ffffff;">SL-095<br>
                        Салатовый</span>
                </div>
                <div class="col-xs-3 col-md-1 text-center"
                    style="padding: 5px; background-image: url(/assets/images/SibaluxColors/SL-018_голубой.jpg); height: 65px;">
                    <span style="color: #ffffff;">SL-018<br>
                        Голубой</span>
                </div>
                <div class="col-xs-3 col-md-1 text-center"
                    style="padding: 5px; background-image: url(/assets/images/SibaluxColors/SL-044_ярко-синий.jpg); height: 65px;">
                    <span style="color: #ffffff;">SL-044<br>
                        Ярко-синий</span>
                </div>
                <div class="col-xs-3 col-md-1 text-center"
                    style="padding: 5px; background-image: url(/assets/images/SibaluxColors/SL-098_фиолетовый.jpg); height: 65px;">
                    <span style="color: #ffffff;">SL-098<br>
                        Фиолетовый</span>
                </div>
                <div class="col-xs-3 col-md-1 text-center"
                    style="padding: 5px; background-image: url(/assets/images/SibaluxColors/SL-100_сигнальный_красный.jpg); height: 65px;">
                    <span style="color: #ffffff;">SL-100<br>
                        Сигнальный красный</span>
                </div>
            </div><br>

            <h6>Stone age collection</h6>
            <div class="row" style="margin-left: 0; margin-right: 0;">
                <div class="col-xs-3 col-md-1 text-center"
                    style="padding: 5px; background-image: url(/assets/images/SibaluxColors/SL-037_красный_травертин.jpg); height: 65px;">
                    <span style="color: #ffffff;">SL-037<br>
                        Красный травертин</span>
                </div>
                <div class="col-xs-3 col-md-1 text-center"
                    style="padding: 5px; background-image: url(/assets/images/SibaluxColors/SL-136_бежевый_гранит.jpg); height: 65px;">
                    <span style="color: #ffffff;">SL-136<br>
                        Бежевый гранит</span>
                </div>
                <div class="col-xs-3 col-md-1 text-center"
                    style="padding: 5px; background-image: url(/assets/images/SibaluxColors/SL-138_травертин.jpg); height: 65px;">
                    <span style="color: #000000;">SL-138<br>
                        Травертин</span>
                </div>
                <div class="col-xs-3 col-md-1 text-center"
                    style="padding: 5px; background-image: url(/assets/images/SibaluxColors/SL-141_бетон_light.jpg); height: 65px;">
                    <span style="color: #000000;">SL-141<br>
                        Бетон light</span>
                </div>
                <div class="col-xs-3 col-md-1 text-center"
                    style="padding: 5px; background-image: url(/assets/images/SibaluxColors/SL-167_серый_бетон.jpg); height: 65px;">
                    <span style="color: #ffffff;">SL-167<br>
                        Серый бетон</span>
                </div>
                <div class="col-xs-3 col-md-1 text-center"
                    style="padding: 5px; background-image: url(/assets/images/SibaluxColors/SL-230_чёрный_бетон.jpg); height: 65px;">
                    <span style="color: #ffffff;">SL-230<br>
                        Чёрный бетон</span>
                </div>
                <div class="col-xs-3 col-md-1 text-center"
                    style="padding: 5px; background-image: url(/assets/images/SibaluxColors/SL-135_чёрный_гранит.jpg); height: 65px;">
                    <span style="color: #ffffff;">SL-135<br>
                        Montenegro</span>
                </div>
                <div class="col-xs-3 col-md-1 text-center"
                    style="padding: 5px; background-image: url(/assets/images/SibaluxColors/SL-149_eternity.jpg); height: 65px;">
                    <span style="color: #000000;">SL-149<br>
                        Eternity</span>
                </div>
                <div class="col-xs-3 col-md-1 text-center"
                    style="padding: 5px; background-image: url(/assets/images/SibaluxColors/SL-140_бетон_grey_шагрень.jpg); height: 65px;">
                    <span style="color: #ffffff;">SL-140<br>
                        Бетон grey шагрень</span>
                </div>
                <div class="col-xs-3 col-md-1 text-center"
                    style="padding: 5px; background-image: url(/assets/images/SibaluxColors/SL-234_бетон_лофт.jpg); height: 65px;">
                    <span style="color: #000000;">SL-234<br>
                        Бетон лофт</span>
                </div>
                <div class="col-xs-3 col-md-1 text-center"
                    style="padding: 5px; background-image: url(/assets/images/SibaluxColors/SL-147-3_liberty.jpg); height: 65px;">
                    <span style="color: #000000;">SL-147/3<br>
                        Liberty</span>
                </div>
            </div><br>

            <h6>Terra collection</h6>
            <div class="row" style="margin-left: 0; margin-right: 0;">
                <div class="col-xs-3 col-md-1 text-center"
                    style="padding: 5px; background-image: url(/assets/images/SibaluxColors/SL-401_terra_white.jpg); height: 65px;">
                    <span style="color: #ffffff;">SL-401<br>
                        Terra white</span>
                </div>
                <div class="col-xs-3 col-md-1 text-center"
                    style="padding: 5px; background-image: url(/assets/images/SibaluxColors/SL-403_terra_ice.jpg); height: 65px;">
                    <span style="color: #ffffff;">SL-403<br>
                        Terra ice</span>
                </div>
                <div class="col-xs-3 col-md-1 text-center"
                    style="padding: 5px; background-image: url(/assets/images/SibaluxColors/SL-411_terra_silk.jpg); height: 65px;">
                    <span style="color: #000000;">SL-411<br>
                        Terra silk</span>
                </div>
                <div class="col-xs-3 col-md-1 text-center"
                    style="padding: 5px; background-image: url(/assets/images/SibaluxColors/SL-412_terra_gray.jpg); height: 65px;">
                    <span style="color: #ffffff;">SL-412<br>
                        Terra gray</span>
                </div>
                <div class="col-xs-3 col-md-1 text-center"
                    style="padding: 5px; background-image: url(/assets/images/SibaluxColors/SL-413_terra_anthrazit.jpg); height: 65px;">
                    <span style="color: #ffffff;">SL-413<br>
                        Terra anthrazit</span>
                </div>
                <div class="col-xs-3 col-md-1 text-center"
                    style="padding: 5px; background-image: url(/assets/images/SibaluxColors/SL-408_terra_ivory.jpg); height: 65px;">
                    <span style="color: #000000;">SL-408<br>
                        Terra ivory</span>
                </div>
                <div class="col-xs-3 col-md-1 text-center"
                    style="padding: 5px; background-image: url(/assets/images/SibaluxColors/SL-402_terra_sand.jpg); height: 65px;">
                    <span style="color: #ffffff;">SL-402<br>
                        Terra sand</span>
                </div>
                <div class="col-xs-3 col-md-1 text-center"
                    style="padding: 5px; background-image: url(/assets/images/SibaluxColors/SL-406_terra_gold.jpg); height: 65px;">
                    <span style="color: #ffffff;">SL-406<br>
                        Terra gold</span>
                </div>
                <div class="col-xs-3 col-md-1 text-center"
                    style="padding: 5px; background-image: url(/assets/images/SibaluxColors/SL-415_terra_copper.jpg); height: 65px;">
                    <span style="color: #ffffff;">SL-415<br>
                        Terra copper</span>
                </div>
                <div class="col-xs-3 col-md-1 text-center"
                    style="padding: 5px; background-image: url(/assets/images/SibaluxColors/SL-405-1_terra_baikal.jpg); height: 65px;">
                    <span style="color: #ffffff;">SL-405/1<br>
                        Terra baikal</span>
                </div>
                <div class="col-xs-3 col-md-1 text-center"
                    style="padding: 5px; background-image: url(/assets/images/SibaluxColors/SL-416_terra_patina.jpg); height: 65px;">
                    <span style="color: #ffffff;">SL-416<br>
                        Terra patina</span>
                </div>
                <div class="col-xs-3 col-md-1 text-center"
                    style="padding: 5px; background-image: url(/assets/images/SibaluxColors/SL-404_beige.jpg); height: 65px;">
                    <span style="color: #ffffff;">SL-404<br>
                        Beige</span>
                </div>
                <div class="col-xs-3 col-md-1 text-center"
                    style="padding: 5px; background-image: url(/assets/images/SibaluxColors/SL-407_terra_extra_gold.jpg); height: 65px;">
                    <span style="color: #ffffff;">SL-407<br>
                        Terra extra gold</span>
                </div>
                <div class="col-xs-3 col-md-1 text-center"
                    style="padding: 5px; background-image: url(/assets/images/SibaluxColors/SL-414_terra_bronze.jpg); height: 65px;">
                    <span style="color: #ffffff;">SL-414<br>
                        Terra bronze</span>
                </div>
                <div class="col-xs-3 col-md-1 text-center"
                    style="padding: 5px; background-image: url(/assets/images/SibaluxColors/SL-410_terra_clay.jpg); height: 65px;">
                    <span style="color: #ffffff;">SL-410<br>
                        Terra clay</span>
                </div>
            </div><br>

            <h6>Sparking collection</h6>
            <div class="row" style="margin-left: 0; margin-right: 0;">
                <div class="col-xs-3 col-md-1 text-center"
                    style="padding: 5px; background-image: url(/assets/images/SibaluxColors/SL-111_искристый_розовый.jpg); height: 65px;">
                    <span style="color: #ffffff;">SL-111<br>
                        Искристый розовый</span>
                </div>
                <div class="col-xs-3 col-md-1 text-center"
                    style="padding: 5px; background-image: url(/assets/images/SibaluxColors/SL-114_искристый_зеленый.jpg); height: 65px;">
                    <span style="color: #ffffff;">SL-138<br>
                        Искристый зеленый</span>
                </div>
                <div class="col-xs-3 col-md-1 text-center"
                    style="padding: 5px; background-image: url(/assets/images/SibaluxColors/SL-124_искристый_чёрный.jpg); height: 65px;">
                    <span style="color: #ffffff;">SL-124<br>
                        Искристый чёрный</span>
                </div>
                <div class="col-xs-3 col-md-1 text-center"
                    style="padding: 5px; background-image: url(/assets/images/SibaluxColors/SL-119_искристый_белый.jpg); height: 65px;">
                    <span style="color: #000000;">SL-119<br>
                        Искристый белый</span>
                </div>
                <div class="col-xs-3 col-md-1 text-center"
                    style="padding: 5px; background-image: url(/assets/images/SibaluxColors/SL-117_искристый_голубой.jpg); height: 65px;">
                    <span style="color: #ffffff;">SL-117<br>
                        Искристый голубой</span>
                </div>
                <div class="col-xs-3 col-md-1 text-center"
                    style="padding: 5px; background-image: url(/assets/images/SibaluxColors/SL-115_искристый_морская_волна.jpg); height: 65px;">
                    <span style="color: #ffffff;">SL-115<br>
                        Искристый морская волна</span>
                </div>
            </div><br>


            <h6>Special collection</h6>
            <div class="row" style="margin-left: 0; margin-right: 0;">
                <div class="col-xs-3 col-md-1 text-center"
                    style="padding: 5px; background-image: url(/assets/images/SibaluxColors/SL-026_золотое_зеркало.jpg); height: 65px;">
                    <span style="color: #000000;">SL-026<br>
                        Золотое зеркало</span>
                </div>
                <div class="col-xs-3 col-md-1 text-center"
                    style="padding: 5px; background-image: url(/assets/images/SibaluxColors/SL-025_серебристое_зеркало.jpg); height: 65px;">
                    <span style="color: #000000;">SL-025<br>
                        Серебристое зеркало</span>
                </div>
                <div class="col-xs-3 col-md-1 text-center"
                    style="padding: 5px; background-image: url(/assets/images/SibaluxColors/SL-027_чайное_зеркало.jpg); height: 65px;">
                    <span style="color: #000000;">SL-027<br>
                        Чайное зеркало</span>
                </div>
                <div class="col-xs-3 col-md-1 text-center"
                    style="padding: 5px; background-image: url(/assets/images/SibaluxColors/SL-028_серебро_шабрированное.jpg); height: 65px;">
                    <span style="color: #000000;">SL-028<br>
                        Серебро шабрированное</span>
                </div>
                <div class="col-xs-3 col-md-1 text-center"
                    style="padding: 5px; background-image: url(/assets/images/SibaluxColors/SL-029_золото_шабрированное.jpg); height: 65px;">
                    <span style="color: #000000;">SL-029<br>
                        Золото шабрированное</span>
                </div>
                <div class="col-xs-3 col-md-1 text-center"
                    style="padding: 5px; background-image: url(/assets/images/SibaluxColors/SL-030_сталь_шабрированная.jpg); height: 65px;">
                    <span style="color: #ffffff;">SL-030<br>
                        Сталь шабрированная</span>
                </div>
            </div><br>

            <h6>Rusty collection</h6>
            <div class="row" style="margin-left: 0; margin-right: 0;">
                <div class="col-xs-3 col-md-1 text-center"
                    style="padding: 5px; background-image: url(/assets/images/SibaluxColors/SL-148_patina.jpg); height: 65px;">
                    <span style="color: #ffffff;">SL-148<br>
                        Patina</span>
                </div>
                <div class="col-xs-3 col-md-1 text-center"
                    style="padding: 5px; background-image: url(/assets/images/SibaluxColors/SL-216_clay.jpg); height: 65px;">
                    <span style="color: #ffffff;">SL-216<br>
                        Clay</span>
                </div>
                <div class="col-xs-3 col-md-1 text-center"
                    style="padding: 5px; background-image: url(/assets/images/SibaluxColors/SL-218_black_steel_gray.jpg); height: 65px;">
                    <span style="color: #ffffff;">SL-218<br>
                        Black steel gray</span>
                </div>
                <div class="col-xs-3 col-md-1 text-center"
                    style="padding: 5px; background-image: url(/assets/images/SibaluxColors/SL-215_light_dark.jpg); height: 65px;">
                    <span style="color: #ffffff;">SL-215<br>
                        Light dark</span>
                </div>
                <div class="col-xs-3 col-md-1 text-center"
                    style="padding: 5px; background-image: url(/assets/images/SibaluxColors/SL-217_rust_spicy.jpg); height: 65px;">
                    <span style="color: #ffffff;">SL-217<br>
                        Rust spicy</span>
                </div>
                <div class="col-xs-3 col-md-1 text-center"
                    style="padding: 5px; background-image: url(/assets/images/SibaluxColors/SL-219_black_steel_smoke.jpg); height: 65px;">
                    <span style="color: #ffffff;">SL-219<br>
                        Black steel smoke</span>
                </div>
            </div><br>

        </div>
    </div>
    <br><br><br> <br><br><br>
</div>
import {Injectable} from "@angular/core";
import {HttpClient, HttpParams } from "@angular/common/http";


@Injectable({
    providedIn: 'root'
})
export class AwardsService {
  constructor(private http: HttpClient) {}
   
//метод получения данных "награды"
  getAwards() {
    return this.http.get('//specialist-kazan.ru/awardsdata');

 }
  
}

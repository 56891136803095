<div class="container">
    <br>
    <h4>НАВЕСНАЯ ФАСАДНАЯ СИСТЕМА ТЕПЛАНТ-ДУЭТ</h4><br>
    <p><span class="orange-bold">Навесная фасадная система Теплант-Дуэт</span> представляет собой декоративный экран из
        алюминиевой композитной панели и предназначена для облицовки наружных стеновых панелей типа "сэндвич". Данный
        продукт является альтернативой использования металлических кассетных панелей с выделенным теневым соединением, а
        также позволяет уйти от монтажа сэндвич-панелей с использованием фасонных элементов для закрытия стыков. Система
        Теплант-Дуэт позволяет подчеркнуть минимализм современного строительства и применяются для придания имиджевого
        внешнего вида строения.</p>

    <p>В качестве облицовочного материала применяются композитные кассеты без профилирования с различной отделкой
        поверхности (анодированная, цветное покрытие различных цветов, под натуральный камень). Окраска композитных
        панелей производится по системе и имеет 12 базовых однотонных цветов и 6 цветов металлик.</p>
    <br>
    <div><img id="duetColor" class="rounded mx-auto d-block" src="/assets/images/production/DuetColor_B.jpg"></div><br>

    <p>Элементы несущего каркаса навесной фасадной системы выполнены из оцинкованного материала, состоят из вертикальных
        и горизонтальных профилей, а также из различных крепежных элементов.</p>
    <p>Базой несущей системы являются <span class="orange-bold">сэндвич-панели Теплант-Concept</span> , к которым
        заклепками прикреплены вертикальные профили. Крепление листового облицовочного материала к вертикальным профилям
        производится заклепками (прижимными профилями), а кассетные панели крепятся с помощью заклепок или на иклях.</p>

    <br>

    <div class="row">
        <div class="col-md-4 border"><a href="/assets/images/production/DuetIcl1_B.jpg" title=""><img id="duet-img"
                    src="/assets/images/production/DuetIcl1_S.jpg"></a></div>
        <div class="col-md-4 border"><a href="/assets/images/production/DuetIcl5_B.jpg" title=""><img id="duet-img"
                    src="/assets/images/production/DuetIcl5_S.jpg"></a></div>
        <div class="col-md-4 border"><a href="/assets/images/production/DuetIcl2_B.jpg" title=""><img id="duet-img"
                    src="/assets/images/production/DuetIcl2_S.jpg"></a></div>

    </div>

    <br><br>
    <p>Для равномерного распределения ветровой нагрузки, нагрузки от направляющих и нагрузки от облицовочного материала
        необходимо профили крепить к внешней облицовке панели заклепками с шагом 200-300мм с максимальным шагом профилей
        750 мм. При расчете допустимой расчетной нагрузки на панели типа «сэндвич» необходимо учитывать дополнительную
        нагрузку от облицовочных кассет.</p>
    <br>

    <div class="row">
        <div class="col-md-6 text-right"><a class="duet3" href="/assets/images/production/DuetIcl3_B.jpg" title="">
                <img src="/assets/images/production/DuetIcl3_S.jpg"></a></div>
        <div class="col-md-6"> <a class="duet4" href="/assets/images/production/DuetIcl4_B.jpg" title="">
                <img src="/assets/images/production/DuetIcl4_S.jpg"></a></div>

    </div>

    <br>
    <p>Этот продукт, наряду с очевидными достоинствами сэндвич-панелей, дает ряд преимуществ: оформление фасадов
        различными цветовыми решения малой величины, дополнительная защита ограждающих конструкций от внешних
        воздействий, скрытая система крепления.</p><br>
    <h5>ТЕХНИЧЕСКИЕ РЕШЕНИЯ НАВЕСНОЙ ФАСАДНОЙ СИСТЕМЫ ДЛЯ СЭНДВИЧ-ПАНЕЛЕЙ</h5><br>

    <div class="row">
        <div><a href="/assets/images/production/Duetuzel1_B.jpg" class="duet5" title=""><img
                    src="/assets/images/production/Duetuzel1_S.jpg"></a></div>
        <div><a href="/assets/images/production/Duetuzel2_B.jpg" class="duet1" title=""><img
                    src="/assets/images/production/Duetuzel2_S.jpg"></a></div>
        <div><a href="/assets/images/production/Duetuzel3_B.jpg" class="duet1" title=""><img
                    src="/assets/images/production/Duetuzel3_S.jpg"></a></div>
        <div><a href="/assets/images/production/Duetuzel4_B.jpg" class="duet5" title=""><img
                    src="/assets/images/production/Duetuzel4_S.jpg"></a></div>
        <div><a href="/assets/images/production/Duetuzel3_B.jpg" class="duet1" title=""><img
                    src="/assets/images/production/Duetuzel3_S.jpg"></a></div>
        <div><a href="/assets/images/production/Duetuzel6_B.jpg" class="duet1" title=""><img
                    src="/assets/images/production/Duetuzel6_S.jpg"></a></div>
        <div><a href="/assets/images/production/Duetuzel7_B.jpg" class="duet5" title=""><img
                    src="/assets/images/production/Duetuzel7_S.jpg"></a></div>
        <div><a href="/assets/images/production/Duetuzel8_B.jpg" class="duet1" title=""><img
                    src="/assets/images/production/Duetuzel8_S.jpg"></a></div>
        <div><a href="/assets/images/production/Duetuzel9_B.jpg" class="duet1" title=""><img
                    src="/assets/images/production/Duetuzel9_S.jpg"></a></div>
        <div><a href="/assets/images/production/Duetuzel10_B.jpg" class="duet6" title=""><img
                    src="/assets/images/production/Duetuzel10_S.jpg"></a></div>
    </div>

    <br><br>
</div>
import {Deserializable} from "./deserializable.model";

export class Awards implements Deserializable {
  
  id: Int32Array; //id награды
  srcAwards: string;  //ссылка на картинку
  awardsName: string;  //название
  

     deserialize(input: any) {
        Object.assign(this, input);
        return this;
      }
}
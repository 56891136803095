<div class="container">
  <br>
  <h4> САМОРЕЗЫ ДЛЯ СЭНДВИЧ-ПАНЕЛЕЙ</h4> <br>
  <img src="/assets/images/production/harpoon_logo.jpg"> ВЕДУЩИЙ ПРОИЗВОДИТЕЛЬ И ПОСТАВЩИК ПРОФЕССИОНАЛЬНОГО КРЕПЕЖА ДЛЯ
  СЭНДВИЧ ПАНЕЛЕЙ.
  <br><br>
  <p><span class="orange-bold">Саморезы для сэндвич-панелей</span> подбирают в соответствии с определенными задачами с
    учетом конструкционных особенностей соединяемых элементов.
    Узлы крепления сэндвич-панелей рассчитываются в соответствии с рекомендациями «Стандарта организации на винты
    самонарезающие Harpoon».</p>

  <table class="table table-bordered mx-auto table-harpoon" style="width: 500px; text-align: center;">
    <thead class="table-info" style="font-size: 13px;">
      <tr>
        <th scope="col" style="width: 276px;">Ширина, мм</th>
        <th scope="col">Толщина панели h, мм</th>
      </tr>
    </thead>
    <tbody>
      <tr>
        <td>5.5/6.3 х 85</td>
        <td>15 - 45</td>
      </tr>
      <tr>
        <td>5.5/6.3 х 105</td>
        <td>35 - 65</td>
      </tr>
      <tr>
        <td>5.5/6.3 х 130</td>
        <td>60 - 90</td>
      </tr>
      <tr>
        <td>5.5/6.3 х 140</td>
        <td>70 - 100</td>
      </tr>
      <tr>
        <td>5.5/6.3 х 150</td>
        <td>60 - 110</td>
      </tr>
      <tr>
        <td>5.5/6.3 х 160</td>
        <td>70 - 120</td>
      </tr>
      <tr>
        <td>5.5/6.3 х 190</td>
        <td>100 - 150</td>
      </tr>
      <tr>
        <td>5.5/6.3 х 240</td>
        <td>150 - 200</td>
      </tr>
      <tr>
        <td>5.5/6.3х 285</td>
        <td>195 - 245</td>
      </tr>
    </tbody>
  </table><br>
  <h6>САМОРЕЗЫ HARPOON HSP-R-S19 ДЛЯ СЭНДВИЧ-ПАНЕЛЕЙ, КРЕПЛЕНИЕ К ПОДКОНСТРУКЦИЯМ ТОЛЩИНОЙ 4-12,5 ММ.</h6><br>
  <div><img id="harpoon_img" src="/assets/images/production/h1.jpg" class="rounded mx-auto d-block"></div>

  <ul>
    <li> 2 резьбы 5.5/6.3 мм, EPDM шайба d = 19 мм из нержавеющей стали AISI 304</li>
    <li> Закаленная углеродистая сталь SAE 1022, антикоррозийное покрытие RUSPERT ® TYPE II</li>
    <li> Увеличенная (h = 5 мм) высота головки самореза для лучшего удержания в бите</li>
  </ul>
  <br>

  <h6>САМОРЕЗЫ HARPOON PLUS HSP 14-R – S19 ДЛЯ СЭНДВИЧ-ПАНЕЛЕЙ, КРЕПЛЕНИЕ К ПОДКОНСТРУКЦИЯМ ТОЛЩИНОЙ 4-14 ММ.</h6><br>
  <p>Запатентованное, не имеющее аналогов на отечественном рынке сверло, просверливает металлоконструкцию из особо
    прочной стали толщиной
    до 16 мм в 2 раза быстрее, по сравнению со стандартным сверлом. Закаленная углеродистая сталь SAE 1022,
    антикоррозийное покрытие RUSPERT ® TYPE II.</p>
  <br>
  <div><img id="harpoon_img" src="/assets/images/production/h2.jpg" class="rounded mx-auto d-block"></div>

  <ul>
    <li> Сверление высокопрочной легированной стали 09Г2С</li>
    <li> Эксклюзивный продукт</li>
    <li> Толщина сверления — 4,0-16,0 мм</li>
    <li> Скорость сверления в 2 раза выше</li>
    <li> 2 резьбы 5.5/6.3 мм, EPDM шайба d = 19 мм из нержавеющей стали AISI 304</li>
    <li> Увеличенная (h = 5 мм) высота головки самореза для лучшего удержания в бите</li>
  </ul>
  <br>
  <h6>САМОРЕЗЫ HARPOON HSP3-R-S19 ДЛЯ СЭНДВИЧ-ПАНЕЛЕЙ, КРЕПЛЕНИЕ К ПОДКОНСТРУКЦИЯМ ТОЛЩИНОЙ 2-5ММ</h6><br>
  <div><img id="harpoon_img" src="/assets/images/production/h3.jpg" class="rounded mx-auto d-block"></div>
  <ul>
    <li> 2 резьбы 5.5/6.3 мм, EPDM шайба d = 19 мм из нержавеющей стали AISI 304</li>
    <li> Закаленная углеродистая сталь SAE 1022, антикоррозийное покрытие RUSPERT ® TYPE II</li>
    <li> Специальная резьба для больших нагрузок на вырыв из тонких (до 5 мм) подконструкций</li>
  </ul>

  <br><br>
</div>
import { Component, OnInit, AfterViewInit, ViewChild, ViewChildren, ChangeDetectorRef } from '@angular/core';
import { NguCarouselConfig } from '@ngu/carousel';
import { ObjectsService } from 'src/app/core/service/objects.service';
import { Objects } from '../../shared/models/objects.model';
import { HttpClient } from '@angular/common/http';
import { StyleColors } from '../../style-colors';
import { Lightbox, LightboxConfig } from 'ngx-lightbox';

@Component({
  selector: 'app-objects-carousel',
  templateUrl: './objects-carousel.component.html',
  styleUrls: ['./objects-carousel.component.scss']
})
export class ObjectsCarouselComponent implements OnInit {
  public isDataLoaded: boolean = false;
  name = 'Angular';



  carouselConfig: NguCarouselConfig = {
    grid: { xs: 1, sm: 3, md: 4, lg: 4, all: 0 },
    load: 3,
    interval: { timing: 4000, initialDelay: 3000 },
    loop: true,
    touch: true,
    velocity: 0.2
  }

  _items: Array<any> = [];
  objectsDataCollection: Objects[] = [];
  constructor(private objectsService: ObjectsService, private cdr: ChangeDetectorRef, private http: HttpClient, private _lightbox: Lightbox) {

  }
  //вызов метода получения данных об объектах
  ngOnInit() {
    this.objectsService.getObjectsCarousel(12).subscribe((data: any) => {
      data.forEach(objectsDataElement => {
        var objectDataElement = (new Objects().deserialize(objectsDataElement));
        const item = {
          src: objectDataElement.imageMaxSrc,
          caption: objectDataElement.caption,
          thumb: objectDataElement.thumbSrc,
          alt: objectDataElement.caption,
          textBgColor: StyleColors.colorsArray[Math.floor(Math.random() * StyleColors.colorsArray.length)]
        };
        this._items.push(item);
      });

    }, (error: any) => { }, () => { this.isDataLoaded = true });
  }
  open(index: number): void {
    // open lightbox
    this._lightbox.open(this._items, index);
  }

  close(): void {
    // close lightbox programmatically
    this._lightbox.close();
  }
}

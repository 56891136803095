<div class="container">

  <div class="row no-gutters">

    <div class="col-md-6  pt-4 pr-3 pl-1">
      <img *ngIf="newsDataId.newsImageDataMax!=null; else noImage" class="card-img rounded-0 border"
        src="data:image/JPEG;base64,{{newsDataId.newsImageDataMax}}" />

      <ng-template #noImage>
        <img class="card-img rounded-0 border" src="/assets/images/news.jpg">
      </ng-template>

    </div>

    <div class="col-md-6">
      <div class="card-body  pl-1 pr-2">
        <span>{{newsDataId.newsDate |date: 'd MMMM yyyy'}}</span><br>
        <h5 class="card-title" style="color: rgb(52, 117, 190);">{{newsDataId.newsText}}</h5>
        <p>{{newsDataId.newsDetails}}</p>
        <span style="font-size: 15px; color: #7c7c7c; float:right;"><i class="fad fa-eye"></i><a>
            {{newsDataId.newsView}}</a></span>
        <a routerLink="/news">К списку новостей</a>

      </div>
    </div>
  </div>
</div>
import { BrowserModule, Title } from '@angular/platform-browser';
import { LOCALE_ID, NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { SharedComponent } from './shared/shared.component';
import { HeaderComponent } from './shared/layout/header/header.component';
import { LayoutComponent } from './shared/layout/layout.component';
import { FooterComponent } from './shared/layout/footer/footer.component';
import { NavbarComponent } from './shared/layout/header/navbar/navbar.component';
import { HomeComponent } from './home/home.component';
import { AboutComponent } from './about/about.component';
import { ContactsComponent } from './contacts/contacts.component';
import { ProductionComponent } from './production/production.component';
import { CorrugatedBoardComponent } from './production/corrugated-board/corrugated-board.component';
import { TechnicalDocumentationComponent } from './technical-documentation/technical-documentation.component';
import { CertificatesComponent } from './certificates/certificates.component';
import { PirPanelsComponent } from './production/pir-panels/pir-panels.component';
import { TeplantDuetComponent } from './production/teplant-duet/teplant-duet.component';
import { StenoviePaneliComponent } from './production/stenovie-paneli/stenovie-paneli.component';
import { NewsComponent } from './news/news.component';
import { ObjectsComponent } from './objects/objects.component';
import { HttpClientModule } from '@angular/common/http';
import { KrovelniePaneliComponent } from './production/krovelnie-paneli/krovelnie-paneli.component';
import { FasonComponent } from './production/fason/fason.component';
import { HarpoonComponent } from './production/harpoon/harpoon.component';

import { NguCarouselModule } from '@ngu/carousel';
import { NewsDetailsComponent } from './news-details/news-details.component';

import { registerLocaleData } from '@angular/common';
import localeRu from '@angular/common/locales/ru';
import {LightboxModule} from 'ngx-lightbox';
import { NewsCarouselComponent } from './home/news-carousel/news-carousel.component';
import { ObjectsCarouselComponent } from './home/objects-carousel/objects-carousel.component';
import { AwardsCarouselComponent } from './home/awards-carousel/awards-carousel.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { SibaluxComponent } from './production/sibalux/sibalux.component';







registerLocaleData(localeRu, 'ru');



@NgModule({
  declarations: [
    AppComponent,
    SharedComponent,
    HeaderComponent,
    LayoutComponent,
    FooterComponent,
    NavbarComponent,
    HomeComponent,
    AboutComponent,
    ContactsComponent,
    ProductionComponent,
    CorrugatedBoardComponent,
    TechnicalDocumentationComponent,
    CertificatesComponent,
    PirPanelsComponent,
    TeplantDuetComponent,
    StenoviePaneliComponent,
    NewsComponent,
    ObjectsComponent,
    KrovelniePaneliComponent,
    FasonComponent,
    HarpoonComponent,

    NewsDetailsComponent,
    NewsCarouselComponent,
    ObjectsCarouselComponent,
    AwardsCarouselComponent,
    SibaluxComponent,
   
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule, 
    NguCarouselModule,
    LightboxModule,
    BrowserAnimationsModule,
    CarouselModule,
  ],
  
  providers: [{provide: LOCALE_ID, useValue: 'ru' }, Title],
  bootstrap: [AppComponent]
})
export class AppModule { 
}

import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-fason',
  templateUrl: './fason.component.html',
  styleUrls: ['./fason.component.scss']
})
export class FasonComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}

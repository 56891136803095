
import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ObjectsService } from  '../core/service/objects.service'
import { data } from 'jquery';
import { Objects } from '../shared/models/objects.model';
import { ActivatedRoute, Router } from '@angular/router';
import { Lightbox,LightboxConfig  } from 'ngx-lightbox';

@Component({
  selector: 'app-objects',
  templateUrl: './objects.component.html',
  styleUrls: ['./objects.component.scss']
})
export class ObjectsComponent {

  loading = false;

  _albums: Array<any> = [];
 

  objectsDataCollection:Objects[] = [];


  constructor(private objectsService: ObjectsService,private _lightbox: Lightbox,private _lightboxConfig: LightboxConfig) {
   }

  ngOnInit() {
    this.loading = true
    this.objectsService.getObjects()
      .subscribe((data:any)=>{
        data.forEach(objectsDataElement => {
          var objectDataElement=(new Objects().deserialize(objectsDataElement));
          const album = {
                    src: objectDataElement.imageMaxSrc,
                   caption: objectDataElement.caption,
                   thumb: objectDataElement.thumbSrc,
                   alt: objectDataElement.caption
                   
                };
                this._albums.push(album);
                this.loading = false
          });
      });
  }
  open(index: number): void {
    // open lightbox
    this._lightbox.open(this._albums, index);
  }
 
  close(): void {
    // close lightbox programmatically
    this._lightbox.close();
  }
}

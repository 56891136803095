<div class="container"><br>

  <div *ngIf="!loading; else loadingBlock">
    <div class="row">
      <div class="card border-0" style="max-width: 550px; line-height: 1.2" *ngFor="let newsData of newsDataCollection">
        <div class="row no-gutters">
          <div class="col-md-4  pt-4 pr-2 pl-1">
            <a [routerLink]="['/news-details', newsData.id]">
              <img *ngIf="newsData.newsImageData!=null; else noImage" class="card-img rounded-0 border"
                src="data:image/JPEG;base64,{{newsData.newsImageData}}" />
              <ng-template #noImage>
                <img class="card-img rounded-0 border" src="/assets/images/news.jpg">
              </ng-template>
            </a>
          </div>
          <div class="col-md-8">
            <a [routerLink]="['/news-details', newsData.id]">
              <div class="card-body  pl-1 pr-2">
                <h6 class="card-title">{{newsData.newsText}}</h6>
                <p class="card-text">{{newsData.newsDetails |slice:0:250}}...</p>
                <div class="row">
                  <div class="col-sm">
                    <p class="card-text"><small class="text-muted">{{newsData.newsDate |date: 'dd.MM.yyyy'}}</small></p>
                  </div>
                  <div class="col-sm">
                    <span style="font-size: 15px; color: #7c7c7c; float:right;"><i class="fad fa-eye"></i><a>
                        {{newsData.newsView}}</a></span>
                  </div>
                </div>
              </div>
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
  <ng-template #loadingBlock>
    <div class="text-center">
      <div class="spinner-border text-info" role="status">
        <span class="sr-only">Loading...</span>
      </div>
    </div>
  </ng-template>

</div><br><br>
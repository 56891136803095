<div class="container px-0">

  <div *ngIf="isDataLoaded">
  <ngu-carousel #myCarousel [inputs]="carouselConfig" [dataSource]="_items">
  <div *nguCarouselDef="let item of _items;" class="item">
    <a [routerLink]="['/news-details', item.id]">
    <div class="card rounded-0" style="width: 17 rem; display: block">
      <img class="card-img-top rounded-0 border-bottom" src="data:image/JPEG;base64,{{item.src}}" [alt]="item.newsText" style="height: 170px;"/>
      
      <div class="card-body  p-2">
        <h6 class="card-title" style="height: 57px;">{{item.newsText}}</h6>
        <p class="card-text" style="line-height: 1.2"><small class="text-muted" >{{item.newsDetails| slice:0:125}}...</small></p>
       
        <div class="row">
          <div class="col-sm">
        <p class="card-text">{{item.date|date: 'dd.MM.yyyy'}}</p>
          </div>
          <div class="col-sm">
        <span style="font-size: 15px; color: #7c7c7c; float:right;"><i class="fad fa-eye"></i><a> {{item.newsView}}</a></span>
          </div>
        </div>

      </div>
    </div>
    </a>
    
  </div>
  
  <button NguCarouselNext class="leftRs"[style.opacity]="myCarousel.isFirst ? 0.5:1">&lt;</button>
  <button NguCarouselPrev class="rightRs"[style.opacity]="myCarousel.isLast ? 0.5:1">&gt;</button>
  <!-- <ul class="myPoint" NguCarouselPoint>
      <li *ngFor="let j1 of myCarousel.pointNumbers; let j = index" [class.active]="j==myCarousel.activePoint" (click)="myCarousel.moveTo(j)"></li>
  </ul> -->
</ngu-carousel>
  </div>
  <div class="d-flex flex-row-reverse bd-highlight allNewsLink"><a routerLink="/news">Все новости</a></div>
  
</div>
import {Deserializable} from "./deserializable.model";

export class News implements Deserializable {
  
  id: Int32Array; //id новости
  newsDate: string;  //дата новости
  newsText: string;  //заголовок
  newsLink: string;   //устарело..не используется
  newsImage: string;   //ссылка на изображение
  newsDetails: string; // текст новости
  newsImageData: string; //новая ссылка на изображение
  newsImageDataMax: string; //увеличенное изображение
  newsView: Int32Array; //счетчик просмотра новостей

     deserialize(input: any) {
        Object.assign(this, input);
        return this;
      }
}
<div class="container">
  <br>
  <h4> СТЕНОВЫЕ СЭНДВИЧ-ПАНЕЛИ</h4> <br>
  <!-- <ul class="list-group list-group-horizontal">
        <li  type="button"  class="list-group-item rounded-0" style="background-color: #ffd400; color: #ffffff; border: none;">Виды профилирования поверхности</li>
        <li type="button"  class="list-group-item" style="background-color: #ff7f0d96; color: #ffffff; border: none;">Основные характеристики</li>
        <li type="button" class="list-group-item" style="background-color: #ea5e1f; color: #ffffff; border: none;">Виды покрытий</li>
        <li type="button" class="list-group-item" style="background-color: #3b94d1; color: #ffffff; border: none;">Допустимые расчетные нагрузки</li>
        <li class="list-group-item rounded-0" style="background-color: #0070b8; color: #ffffff; border: none;">Соответствие требованиям ISO 12944</li>
      </ul>  -->
  <div class="btn-mobile">
    <div class="btn-group" role="group" aria-label="Basic example">
      <a href="production/stenovie-paneli/#profiles" class="btn btn-info" role="button">Виды профилирования
        поверхности</a>
      <a href="production/stenovie-paneli/#main-characteristic" class="btn btn-secondary" role="button">Основные
        характеристики</a>
      <a href="production/stenovie-paneli/#types" class="btn btn-info" role="button">Виды покрытий</a>
      <a href="production/stenovie-paneli/#load" class="btn btn-secondary" role="button">Допустимые расчетные
        нагрузки</a>
      <a href="production/stenovie-paneli/#iso" class="btn btn-info" role="button">Соответствие требованиям ISO
        12944</a>
    </div>
    <br><br>
  </div>
  <p>Сэндвич-панели данного типа (имеют маркировку ПСБ) применяются для возведения стеновых ограждающих конструкций на
    торговых, промышленных и гражданских объектах. Допускается использовать здания с ограждающими стеновыми
    конструкциями из сэндвич панелей возводимых в I-VI районах по ветровой нагрузке согласно СНиП 2.01.07.85. При
    изготовлении панелей Teplant используются профнастил с полимерным покрытием (ГОСТ Р 52146-2003) и теплоизоляция на
    основе базальтового волокна (ТУ 5761-007-01395087-01).</p>
  <br>
  <div><img id="stenov_sp" class="rounded mx-auto d-block" src="/assets/images/production/stenov_sp.jpg"></div><br>
  <h5 id="profiles">Виды профилирования поверхности внешнего и внутреннего листов:</h5><br>

  <div class="container">
    <div class="row justify-content-md-center">
      <div class="col-6 col-sm-3 p-1">Волна<br><img id="kinds_profile" src="/assets/images/production/wave.jpg"></div>
      <div class="col-6 col-sm-3 p-1">Ровная<br><img id="kinds_profile" src="/assets/images/production/wavenone.jpg">
      </div>
      <div class="w-100"></div>
      <div class="col-6 col-sm-3 p-1">Накатка<br><img id="kinds_profile" src="/assets/images/production/nakatka.jpg">
      </div>
      <div class="col-6 col-sm-3 p-1">Профиль<br><img id="kinds_profile" src="/assets/images/production/profile.jpg">
      </div>
    </div>
  </div>
  <br>

  <h5>Предлагается 2 варианта замкового соединения:</h5><br>
  <div class="container">
    <div class="row justify-content-md-center">
      <div class="col-6"><img id="shadowlock" class="rounded mx-auto d-block"
          src="/assets/images/production/shadowlock.jpg"></div>
      <div class="col-6"><img id="stnlock" class="rounded mx-auto d-block" src="/assets/images/production/stnlock.jpg">
      </div>
    </div>
    <div class="row justify-content-md-center">
      <div class="col-6 text-justify">Теневой замок, «тень» в 20 мм которого позволяет компенсировать
        неровности монтажа, а также реализовать высокоэстетичные дизайнерские проекты,
        применяя вместо дорогостоящих металлических кассетных панелей.</div>
      <div class="col-6 text-justify">Пазогребневый замок, у которого зазор составляет 2 мм</div>
    </div>
  </div>
  <br>

  <p>В качестве среднего теплоизоляционного слоя применяются ламели длиной 2400 мм из минеральной ваты Vatta Rus на
    основе базальтового волокна на синтетическом связующем с гидрофобизирующими добавками плотностью от 110 кг/м3 <span
      class="orange-bold">Teplant – Concept (Концепт)</span> и плотностью от 95 кг/м3 <span class="orange-bold">Teplant
      – Universal (Универсал)</span></p>
  <br>
  <h5 id="main-characteristic">ХАРАКТЕРИСТИКА СТЕНОВЫХ ПАНЕЛЕЙ TEPLANT – CONCEPT (КОНЦЕПТ)</h5> <br>

  <div class="table-cover">
    <table id="characteristics" class="table table-bordered" style="width: 902px; text-align: center;">
      <thead class="table-info p-1" style="font-size: 13px;">
        <tr>
          <th scope="col">Ширина, мм</th>
          <th scope="col">Длина, мм</th>
          <th scope="col">Толщина, мм</th>
          <th scope="col">Приведенное сопротивление теплопередаче, Ro (м2х<sup _ngcontent-kwv-c23="">o</sup>С/Вт)</th>
          <th scope="col">Вес, кг/м2</th>
          <th scope="col">Предел огнестойкости</th>
          <th scope="col">Горючесть утеплителя, ГОСТ 30244</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td class="align-middle">1000</td>
          <td class="align-middle">до 9000</td>
          <td class="align-middle">50</td>
          <td class="align-middle">1,378</td>
          <td class="align-middle">15,0</td>
          <td class="align-middle">EI-30</td>
          <td> </td>
        </tr>
        <tr>
          <td class="align-middle" rowspan="6">1 000 <br>
            1 190</td>
          <td class="align-middle" rowspan="6">До <br>14000</td>
          <td>80</td>
          <td>2,110</td>
          <td>18,6</td>
          <td>EI-90</td>
          <td class="align-middle" rowspan="6">НГ</td>
        </tr>
        <tr>
          <td>100</td>
          <td>2,597</td>
          <td>21,0</td>
          <td>EI-90</td>
        </tr>
        <tr>
          <td>120</td>
          <td>3,085</td>
          <td>23,4</td>
          <td>EI-150</td>
        </tr>
        <tr>
          <td>150</td>
          <td>3,817</td>
          <td>27,0</td>
          <td>EI-90</td>
        </tr>
        <tr>
          <td>200</td>
          <td>5,036</td>
          <td>33,0</td>
          <td>EI-180</td>
        </tr>
        <tr>
          <td>250</td>
          <td>6,256</td>
          <td>41,0</td>
          <td>EI-180</td>
        </tr>
      </tbody>
    </table>
  </div>


  <div class="table-cover">
    <table class="table table-bordered " style="text-align: center;">
      <thead class="table-info p-1" style="font-size: 13px;">
        <tr>
          <th scope="col">Коэффициент теплопроводности 10, Вт/мК</th>
          <th scope="col">Коэффициент теплопроводности 25, Вт/мК</th>
          <th scope="col">Коэффициент теплопроводности А, Вт/мК </th>
          <th scope="col">Коэффициент теплопроводности B, Вт/мК </th>
          <th class="align-middle" scope="col">Сжимаемость, не более, %</th>
          <th scope="col">Прочность на отрыв слоев,МПа</th>
          <th scope="col">Водопоглощение по объему, не более, %</th>
          <th class="align-middle" scope="col">Паропроницаемость, мг/(м*чПа)</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>0,032</td>
          <td>0,036</td>
          <td>0,038</td>
          <td>0,040</td>
          <td>4</td>
          <td>0,003</td>
          <td>2</td>
          <td>0,55</td>
        </tr>
      </tbody>
    </table>
  </div>
  <br>
  <h5>ХАРАКТЕРИСТИКА СТЕНОВЫХ ПАНЕЛЕЙ TEPLANT – UNIVERSAL (УНИВЕРСАЛ)</h5> <br>

  <div class="table-cover">
    <table class="table table-bordered" style="width: 902px; text-align: center;">
      <thead class="table-info p-1" style="font-size: 13px;">
        <tr>
          <th scope="col">Ширина, мм</th>
          <th scope="col">Длина, мм</th>
          <th scope="col">Толщина, мм</th>
          <th scope="col">Приведенное сопротивление теплопередаче, Ro (м2х<sup _ngcontent-kwv-c23="">o</sup>С/Вт)</th>
          <th scope="col">Вес, кг/м2</th>
          <th scope="col">Предел огнестойкости</th>
          <th scope="col">Горючесть утеплителя, ГОСТ 30244</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td class="align-middle">1000</td>
          <td class="align-middle">до 6000</td>
          <td class="align-middle">80</td>
          <td class="align-middle">2,158</td>
          <td class="align-middle">17,0</td>
          <td class="align-middle">EI-90</td>
          <td> </td>
        </tr>
        <tr>
          <td class="align-middle" rowspan="6">1 000 <br>
            1 190</td>
          <td class="align-middle" rowspan="6">До <br>9 000</td>
          <td>100</td>
          <td>2,658</td>
          <td>19,0</td>
          <td>EI-90</td>
          <td class="align-middle" rowspan="6">НГ</td>
        </tr>
        <tr>
          <td>120</td>
          <td>3,158</td>
          <td>21,0</td>
          <td>EI-150</td>
        </tr>
        <tr>
          <td>150</td>
          <td>3,908</td>
          <td>24,0</td>
          <td>EI-150</td>
        </tr>
        <tr>
          <td>200</td>
          <td>5,158</td>
          <td>29,0</td>
          <td>EI-150</td>
        </tr>
      </tbody>
    </table>
  </div>

  <div class="table-cover">
    <table class="table table-bordered " style="text-align: center;">
      <thead class="table-info p-1" style="font-size: 13px;">
        <tr>
          <th scope="col">Коэффициент теплопроводности 10, Вт/мК</th>
          <th scope="col">Коэффициент теплопроводности 25, Вт/мК</th>
          <th scope="col">Коэффициент теплопроводности А, Вт/мК </th>
          <th scope="col">Коэффициент теплопроводности B, Вт/мК </th>
          <th class="align-middle" scope="col">Сжимаемость, не более, %</th>
          <th scope="col">Прочность на отрыв слоев,МПа</th>
          <th scope="col">Водопоглощение по объему, не более, %</th>
          <th class="align-middle" scope="col">Паропроницаемость, мг/(м*чПа)</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>0,033</td>
          <td>0,036</td>
          <td>0,039</td>
          <td>0,042</td>
          <td>6</td>
          <td>0,003</td>
          <td>2</td>
          <td>0,56</td>
        </tr>
      </tbody>
    </table>
  </div>
  <br>
  <p><span class="orange-bold">Стеновые сэндвич-панели Теплант</span> имеют следующие технические особенности, которые
    определяют высокое качество продукции:</p>
  <ul>
    <li>формирование замка панели с помощью 12 каландров – именно благодаря формированию замка в 12 этапов мы можем
      применять на стеновых панелях металл толщиной 0,5мм, т.к. другие линии используют формирование замка в 4-6 этапов,
      а при таком конструктиве на 0,5-м металле с большой вероятностью возникает эффект «волны», этот эффект наблюдается
      реже при использовании металла толщиной 0,6мм. Именно поэтому другие производители в качестве обшивок используют
      металл толщиной 0,6мм;</li>
    <li>плотное прилегание в замковом соединении за счет наклонных полок и точной геометрии - не требуется
      дополнительная герметизация замкового соединения; </li>
    <li>зазор 2 мм на наружной стороне замка позволяет компенсировать температурные расширения и тем самым исключить
      нарушение замкового соединения;</li>
    <li>собственное производство базальтового утеплителя, длина ламелей 2400 мм уменьшает количество стыков в 2 раза,
      влияет на прочность и долговечность продукции;</li>
    <li>использование двухкомпонентного, не вспенивающегося, эластичного клея Дунапол. Подача готовой клеевой смеси
      осуществляется форсунками высокого давления – равномерное нанесение. Наличие дополнительной клеевой для нанесения
      однокомпонентного клея на трапеции при изготовлении кровельных сэндвич-панелей (подача форсунками высокого
      давления);</li>
    <li>пластинчатый подогреваемый пресс длиной 24 м обеспечивает 100% полимеризацию клея и максимально точную геометрию
      панели.</li>
  </ul>
  <br>
  <h5 id="load">ДОПУСТИМЫЕ РАСЧЕТНЫЕ НАГРУЗКИ НА СТЕНОВЫЕ ПАНЕЛИ</h5><br>
  <ul>
    <li><a href="/assets/files/Расчетные нагрузки Теплант Концепт.pdf" target="_blank"><em>Teplant – Concept
          (Концепт)</em></a></li>
    <li><a href="/assets/files/Расчетные нагрузки Теплант Универсал.pdf" target="_blank"><em>Teplant – Universal
          (Универсал)</em></a></li>
  </ul>
  <br>
  <h5 id="types">ВИДЫ ЗАЩИТНО-ДЕКОРАТИВНЫХ ПОКРЫТИЙ СТЕНОВЫХ ПАНЕЛЕЙ ТЕПЛАНТ</h5><br>
  <h4><span class="badge badge-pill badge-info">Полиэфирные (полиэстр)</span></h4>

  <p>Покрытие отвечает высоким требованиям промышленной гигиены, повышенным требованиям к внешнему виду,
    физико-механическим характеристикам, химической стойкости и долговечности (категория нагрузки С4). Базовые
    (стандартные) цвета: 1014, 1015, 1018, 3003, 3005, 5005, 5015, 6005, 6018, 7004, 7005, 7024, 7035, 7047, 8017, 9002, 9003, 9006, 9010, 9016.</p>
  <p> Особенности покрытия:</p>
  <ul>
    <li>толщина сухой пленки составляет от 20 мкм до 25 мкм -оптимальная эластичность при низких температурах;</li>
    <li>оптимальная твердость;</li>
    <li>возможность получения декоративного покрытия с лаком «металлик» с повышенной толщиной покрытия;</li>
    <li>стойкость к профилированию;</li>
    <li>срок службы до ремонтного окрашивания при нормальных условиях эксплуатации составляет более 10 лет.</li>
  </ul>
  <br>

  <h4><span class="badge badge-pill badge-info">Полиуретановые (пурал)</span></h4>
  <p>Это полимерное покрытие на основе полиуретановой смолы(категория нагрузки С4).</p>
  <p>Особенности покрытия:</p>
  <ul>
    <li>продукт модифицирован полиамидными частицами и имеет текстурированную внешнюю поверхность;</li>
    <li>толщина сухой пленки составляет 40 - 50мкм;</li>
    <li>повышенная стойкость к температурным перепадам;</li>
    <li>повышенная эластичность при низких температурах;</li>
    <li>высокая стойкость к воздействию различных климатических факторов;</li>
    <li>стойкость к УФ излучению;</li>
    <li>высокая стойкость к абразивному истиранию;</li>
    <li>хорошие грязеотталкивающие свойства;</li>
    <li>поверхность покрытия препятствует скольжению;</li>
    <li>срок службы до ремонтного окрашивания при нормальных условиях эксплуатации составляет около 15 лет.</li>
  </ul>
  <h4>&nbsp;Эксклюзивные покрытия:&nbsp;</h4><br>
  <h4><span class="badge badge-pill badge-info">HardCoat™</span></h4>
  <p>"Cамое твердое покрытие для наружных строительных конструкций с повышенными требованиями к износостойкости
    поверхности (категория нагрузки С4).</p>
  <p> Особенности покрытия:</p>
  <ul>
    <li>в составе покрытия из полиэстра содержатся керамические микрогранулы, за счет чего образуется мелкозернистая
      структура эмали, что обуславливает рекордную стойкость HardCoat™ к механическим воздействиям;</li>
    <li>представляет собой лицевую эмаль на основе полиэфирных высокомолекулярных смол с низким блеском модифицированное
      керамическими гранулами,матовая поверхность придаст современной архитектуре благородный вид;</li>
    <li>широкий диапазон допустимых рабочих температур увеличивает возможности сезонного монтажа;</li>
    <li>срок службы покрытия при нормальных условиях эксплуатации составляет более 20 лет.</li>
  </ul>
  <br>
  <h4><span class="badge badge-pill badge-info">Antigraffity(AGF)</span></h4>
  <p>Cистема покрытий, включающая модифицированные кремниевые наночастицы на основе полимерных смол. (категория нагрузки
    С5-М)</p>
  <p> Особенности покрытия:</p>
  <ul>
    <li>использование в структуре покрытия кремниевых наночастиц создает уникальное свойство поверхности, защищающее ее
      от нанесения какой-либо другой краски. При попадании на поверхность слоя AGF посторонняя краска легко удаляется;
    </li>
    <li>гладкая текстура AGF обладает способностью легко очищаться от любого вида загрязнений, в т.ч. характеризуется
      повышенной стойкостью к промышленному загрязнению;</li>
    <li>отвечает высоким санитарно-эпидемиологическим требованиям и является экологически чистым продуктом;;</li>
    <li>срок службы покрытия при нормальных условиях эксплуатации составляет до 50 лет в зависимости от среды
      эксплуатации построенного объекта. Нанесение покрытия AGF на собственной линии по окраске стального оцинкованного
      листа, позволяет взять на себя гарантийные обязательства по данному виду покрытия сроком от 7 до 50 лет в
      зависимости от среды эксплуатации построенного объекта.</li>
  </ul><br>
  <h4><span class="badge badge-pill badge-info">Лак «Металлик»</span></h4>
  <p>Лак «Металлик» - специальный лак с эффектом «металлик» для покрытия гладкий полиэстр, наносится на любой цвет по
    желанию заказчика.</p>
  <p>Лак «Металлик» изготовлен на основе полиэфирных смол и содержит пигмент в виде частиц металла. Свет, отражаясь от
    поверхности металла под различными углами, придает зданию неповторимый колорит.</p>
  <br>
  <h5 id="iso">СООТВЕТСТВИЕ СИСТЕМ АНТИКОРРОЗИОННЫХ ПОКРЫТИЙ ТРЕБОВАНИЯМ ISO 12944</h5><br>

  <div class="table-cover">
    <table class="table table-bordered" style="width: 902px; text-align: center;">
      <thead class="table-info p-1" style="font-size: 13px;">
        <tr>
          <th class="align-middle" rowspan="2" scope="col">Категория среды</th>
          <th class="align-middle" rowspan="2" scope="col">Характеристики</th>
          <th colspan="3" scope="col">Система антикоррозионных покрытий Теплант</th>

          <th class="align-middle" rowspan="2" scope="col">ПВДФ</th>
          <th rowspan="2" scope="col">Срок службы согласно ISO 12944</th>
        </tr>
        <tr>
          <th scope="col">Полиэстр</th>
          <th scope="col">Пурал</th>
          <th scope="col">Антиграффити</th>
        </tr>
      </thead>


      <tbody>
        <tr>
          <td class="align-middle" rowspan="2">С4 (промышленные и прибрежные зоны с умеренной концентрацией соли </td>
          <td>Водный конденсат, 480 часов</td>
          <td class="align-middle"><span style="font-size: 20px; color: #39eb7d; text-align: center;"><i
                class="fas fa-check"></i></span></td>
          <td class="align-middle"><span style="font-size: 20px; color: #39eb7d; text-align: center;"><i
                class="fas fa-check"></i></span></td>
          <td class="align-middle"><span style="font-size: 20px; color: #39eb7d; text-align: center;"><i
                class="fas fa-check"></i></span></td>
          <td class="align-middle"><span style="font-size: 20px; color: #39eb7d; text-align: center;"><i
                class="fas fa-check"></i></span></td>
          <td class="align-middle" rowspan="2">более 15 лет</td>
        </tr>
        <tr>
          <td>Нейтральный соляной туман, 720 часов</td>
          <td class="align-middle"><span style="font-size: 20px; color: #39eb7d; text-align: center;"><i
                class="fas fa-check"></i></span></td>
          <td class="align-middle"><span style="font-size: 20px; color: #39eb7d; text-align: center;"><i
                class="fas fa-check"></i></span></td>
          <td class="align-middle"><span style="font-size: 20px; color: #39eb7d; text-align: center;"><i
                class="fas fa-check"></i></span></td>
          <td class="align-middle"><span style="font-size: 20px; color: #39eb7d; text-align: center;"><i
                class="fas fa-check"></i></span></td>
        </tr>
        <tr>
          <td class="align-middle" rowspan="2">С5-М (прибрежные и морские территории с высокой концентрацией соли) </td>
          <td>Водный конденсат, 720 часов</td>
          <td class="align-middle"><span style="font-size: 20px; color: #39eb7d; text-align: center;"><i
                class="fas fa-check"></i></span></td>
          <td class="align-middle"><span style="font-size: 20px; color: #39eb7d; text-align: center;"><i
                class="fas fa-check"></i></span></td>
          <td class="align-middle"><span style="font-size: 20px; color: #39eb7d; text-align: center;"><i
                class="fas fa-check"></i></span></td>
          <td class="align-middle"><span style="font-size: 20px; color: #39eb7d; text-align: center;"><i
                class="fas fa-check"></i></span></td>
          <td class="align-middle" rowspan="2">более 15 лет</td>
        </tr>
        <tr>
          <td class="align-middle">Нейтральный соляной туман, 1440 часов</td>
          <td class="align-middle"><span style="font-size: 20px; color: #39eb7d; text-align: center;"><i
                class="fas fa-check"></i></span></td>
          <td class="align-middle p-0"><span style="font-size: 20px; color: #39eb7d; text-align: center;"><i
                class="fas fa-check"></i></span><br>(с небольшим отклонением в пределах нормы)</td>
          <td class="align-middle"><span style="font-size: 20px; color: #39eb7d; text-align: center;"><i
                class="fas fa-check"></i></span></td>
          <td class="align-middle"><span style="font-size: 20px; color: #39eb7d; text-align: center;"><i
                class="fas fa-check"></i></span></td>
        </tr>
        <tr>
          <td class="align-middle" rowspan="3">С5-I (промышленные зоны с высокой влажностью и агрессивной атмосферой )
          </td>
          <td>Водный конденсат, 720 часов</td>
          <td class="align-middle"><span style="font-size: 20px; color: #39eb7d; text-align: center;"><i
                class="fas fa-check"></i></span></td>
          <td class="align-middle"><span style="font-size: 20px; color: #39eb7d; text-align: center;"><i
                class="fas fa-check"></i></span></td>
          <td class="align-middle"><span style="font-size: 20px; color: #39eb7d; text-align: center;"><i
                class="fas fa-check"></i></span></td>
          <td class="align-middle"><span style="font-size: 20px; color: #39eb7d; text-align: center;"><i
                class="fas fa-check"></i></span></td>
          <td class="align-middle" rowspan="3">более 15 лет</td>
        </tr>
        <tr>
          <td class="align-middle">Соляной туман, 1440 часов</td>
          <td class="align-middle"><span style="font-size: 20px; color: #39eb7d; text-align: center;"><i
                class="fas fa-check"></i></span></td>
          <td class="align-middle p-0"><span style="font-size: 20px; color: #39eb7d; text-align: center;"><i
                class="fas fa-check"></i></span><br>(с небольшим отклонением в пределах нормы)</td>
          <td class="align-middle"><span style="font-size: 20px; color: #39eb7d; text-align: center;"><i
                class="fas fa-check"></i></span></td>
          <td class="align-middle"><span style="font-size: 20px; color: #39eb7d; text-align: center;"><i
                class="fas fa-check"></i></span></td>
        </tr>
        <tr>
          <td>Противостояние хим.веществам (10% раствор H2SO4, 10% раствор NaOH, уайт-спирит), 168 часов</td>
          <td class="align-middle"><span style="font-size: 20px; color: #d83e2a; text-align: center;"><i
                class="fas fa-times"></i></span></td>
          <td class="align-middle"><span style="font-size: 20px; color: #d83e2a; text-align: center;"><i
                class="fas fa-times"></i></span></td>
          <td class="align-middle"><span style="font-size: 20px; color: #39eb7d; text-align: center;"><i
                class="fas fa-check"></i></span> H2SO4 и уайт-спирит <span
              style="font-size: 20px; color: #d83e2a; text-align: center;"><i class="fas fa-times"></i></span> NaOH (96
            часов)</td>
          <td class="align-middle"><span style="font-size: 20px; color: #39eb7d; text-align: center;"><i
                class="fas fa-check"></i></span></td>
        </tr>
      </tbody>
    </table>
  </div>
  <p>Монтаж <span class="orange-bold">стеновых сэндвич-панелей Teplant</span> производится согласно инструкции <a
      href="/assets/files/Инструкция по монтажу.pdf"><em>ИС 5284-013-01395087-2012</em></a>.</p>
  <p>Срок службы <span class="orange-bold">стеновых сэндвич-панелей Teplant</span> не менее 20 лет, при соблюдении
    условий транспортирования, хранения, монтажа и эксплуатации.</p>
  <br><br>
</div>
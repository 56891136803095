<div class="container">
    <br>
    <h4>ПРОФНАСТИЛ</h4><br>
    <p>Профнастил представляет собой прокат с повторяющимися по всей ширине гофрами трапециевидной формы сечения высотой
        h от 35 до 160 мм для настилов и от 8 до 44 мм - для стенового ограждения. Профнастил является одним из наиболее
        востребованных современных строительных материалов, сочетает в себе оптимальную эффективную ширину, несущие
        характеристики, превосходную геометрию профиля, позволяющую осуществить надежное соединение листов. Это
        позволяет широко применять профилированный лист во многих строительных конструкциях в качестве как кровельного,
        так и стенового отделочного ограждающего материала. </p>
    <p>
        Профилированные листы относятся по назначению к типу:
        <br>
        - профилированных листов для стеновых ограждений (С, СС);
        <br>
        - для настила и стеновых ограждений (НС);
        <br>
        - для настила покрытий (Н).
        <br>
        - по геометрическим размерам;
        <br>
        - по наличию защитно-декоративного лакокрасочного покрытия.<br>
    </p>

    <div class="container">
        <div class="row">

            <div class="card m-2 rounded-0" style="width: 16rem;">
                <img class="card-img-top" src="/assets/images/production/corrugated_board/pn_c8.jpg"
                    alt="Card image cap">
                <div class="card-body">
                    <h5 class="card-title">Профнастил С8</h5>
                    <p class="card-text"><small>Габаритная ширина, мм: 1200 <br> Монтажная ширина, мм: 1170 <br> Толщина
                            металла. мм: 0,4;0,45;0,5;0,6;0,7;0,8;0,9</small></p>

                </div>
            </div>

            <div class="card m-2 rounded-0" style="width: 16rem;">
                <img class="card-img-top" src="/assets/images/production/corrugated_board/pn_cc10.jpg"
                    alt="Card image cap">
                <div class="card-body">
                    <h5 class="card-title">Профнастил СC10</h5>
                    <p class="card-text"><small>Габаритная ширина, мм: 1154 <br>Монтажная ширина, мм: 1000 <br>Толщина
                            металла. мм: 0,4;0,45;0,5;0,6;0,7;0,8;0,9</small></p>
                </div>

            </div>

            <div class="card m-2 rounded-0" style="width: 16rem;">
                <img class="card-img-top" src="/assets/images/production/corrugated_board/pn_c10.jpg"
                    alt="Card image cap">
                <div class="card-body">
                    <h5 class="card-title">Профнастил С10</h5>
                    <p class="card-text"><small>Габаритная ширина, мм: 1022/1154 <br>Монтажная ширина, мм: 1000/1100<br>
                            Толщина металла. мм: 0,4;0,45;0,5;0,55;0,6;0,7;0,8;0,9</small></p>
                </div>
            </div>




            <div class="card m-2 rounded-0" style="width: 16rem;">
                <img class="card-img-top" src="/assets/images/production/corrugated_board/pn_c18.jpg"
                    alt="Card image cap">
                <div class="card-body">
                    <h5 class="card-title">Профнастил С18</h5>
                    <p class="card-text"><small>Габаритная ширина, мм: 1023 <br> Монтажная ширина, мм: 1000 <br> Толщина
                            металла. мм: 0,4;0,45;0,5;0,6;0,7;0,8;0,9</small></p>
                </div>
            </div>



            <div class="card m-2 rounded-0" style="width: 16rem;">
                <img class="card-img-top" src="/assets/images/production/corrugated_board/pn_c21.jpg"
                    alt="Card image cap">
                <div class="card-body">
                    <h5 class="card-title">Профнастил С21</h5>
                    <p class="card-text"><small>Габаритная ширина, мм: 1051 <br>Монтажная ширина, мм: 1000 <br>Толщина
                            металла. мм: 0,4;0,45;0,5;0,55;0,6;0,7;0,8;0,9</small></p>
                </div>
            </div>



            <div class="card m-2 rounded-0" style="width: 16rem; ">
                <img class="card-img-top" src="/assets/images/production/corrugated_board/pn_nc35.jpg"
                    alt="Card image cap">
                <div class="card-body">
                    <h5 class="card-title">Профнастил НС35</h5>
                    <p class="card-text"><small>Габаритная ширина, мм: 1060 <br>Монтажная ширина, мм: 1000<br> Толщина
                            металла. мм: 0,45;0,5;0,55;0,6;0,7;0,8;0,9</small></p>
                </div>
            </div>




            <div class="card m-2 rounded-0" style="width: 16rem;">
                <img class="card-img-top" src="/assets/images/production/corrugated_board/pn_c44.jpg"
                    alt="Card image cap">
                <div class="card-body">
                    <h5 class="card-title">Профнастил С44</h5>
                    <p class="card-text"><small>Габаритная ширина, мм: 1047 <br> Монтажная ширина, мм: 1000 <br>Толщина
                            металла. мм: 0,45;0,5;0,6;0,7;0,8;0,9</small></p>
                </div>
            </div>



            <div class="card m-2 rounded-0" style="width: 16rem;">
                <img class="card-img-top" src="/assets/images/production/corrugated_board/pn_nc44.jpg"
                    alt="Card image cap">
                <div class="card-body">
                    <h5 class="card-title">Профнастил НС44</h5>
                    <p class="card-text"><small>Габаритная ширина, мм: 1077 <br> Монтажная ширина, мм: 1000<br> Толщина
                            металла. мм: 0,45;0,5;0,6;0,7;0,8;0,9</small></p>
                </div>
            </div>



            <div class="card m-2 rounded-0" style="width: 16rem;">
                <img class="card-img-top" src="/assets/images/production/corrugated_board/pn_n57.jpg"
                    alt="Card image cap">
                <div class="card-body">
                    <h5 class="card-title">Профнастил Н57 </h5>
                    <p class="card-text"><small>Габаритная ширина, мм: 801 <br>Монтажная ширина, мм: 750<br>Толщина
                            металла. мм:<br> 0,6; 0,7; 0,8; 0,9</small></p>
                </div>
            </div>



            <div class="card m-2 rounded-0" style="width: 16rem;">
                <img class="card-img-top" src="/assets/images/production/corrugated_board/pn_n60.jpg"
                    alt="Card image cap">
                <div class="card-body">
                    <h5 class="card-title">Профнастил Н60</h5>
                    <p class="card-text"><small>Габаритная ширина, мм: 902 <br> Монтажная ширина, мм: 845<br> Толщина
                            металла. мм: 0,6;0,7;0,8;0,9;1,0</small></p>
                </div>
            </div>



            <div class="card m-2 rounded-0" style="width: 16rem;">
                <img class="card-img-top" src="/assets/images/production/corrugated_board/pn_n75.jpg"
                    alt="Card image cap">
                <div class="card-body">
                    <h5 class="card-title">Профнастил Н75</h5>
                    <p class="card-text"><small>Габаритная ширина, мм: 800 <br> Монтажная ширина, мм: 750 <br>Толщина
                            металла. мм: 0,7;0,8;0,9;1,0</small></p>
                </div>
            </div>



            <div class="card m-2 rounded-0" style="width: 16rem;">
                <img class="card-img-top" src="/assets/images/production/corrugated_board/pn_n114.jpg"
                    alt="Card image cap">
                <div class="card-body">
                    <h5 class="card-title">Профнастил Н114 </h5>
                    <p class="card-text"><small>Габаритная ширина, мм: 646/807 <br>Монтажная ширина, мм: 600/750<br>
                            Толщина металла. мм: 0,8;0,9;1,0;1,2;1,5</small></p>
                </div>
            </div>

        </div>

        <br><br>
        <h5>БАЗОВЫЕ СТАНДАРТНЫЕ ЦВЕТА</h5><br>
        <div class="text-center" style="font-weight: 300; font-size: 12px;">
            <div class="text-center" style="font-weight: 300; font-size: 12px;">

                <div class="row" style="margin-left: 0; margin-right: 0;">
                    <div class="col-xs-3 col-md-1 text-center"
                        style="padding: 5px; background-color: #dfcea1; height: 65px;">
                        <span style="color: #000000;">RAL 1014<br>
                            Слоновая кость</span>
                    </div>
                    <div class="col-xs-3 col-md-1 text-center"
                        style="padding: 5px; background-color: #e6d9bd; height: 65px;">
                        <span style="color: #000000;">RAL 1015<br>
                            Легкий слоновый</span>
                    </div>
                    <div class="col-xs-3 col-md-1 text-center"
                        style="padding: 5px; background-color: #fdda38; height: 65px;">
                        <span style="color: #000000;">RAL 1018<br>
                            Желтый цинк</span>
                    </div>
                    <!-- <div class="col-xs-3 col-md-1 text-center"
                        style="padding: 5px; background-color: #e8540d; height: 65px;">
                        <span style="color: #ffffff;">RAL 2004<br>
                            Чистый оранжевый</span>
                    </div> -->
                    <!-- <div class="col-xs-3 col-md-1 text-center"
                        style="padding: 5px; background-color: #f46f29; height: 65px;">
                        <span style="color: #ffffff;">RAL 2008<br>
                            Яркий красно-оранжевый</span>
                    </div>-->
                    <div class="col-xs-3 col-md-1 text-center"
                        style="padding: 5px; background-color: #870a24; height: 65px;">
                        <span style="color: #ffffff;">RAL 3003<br>
                            Красный рубин</span>
                    </div> 
                    <div class="col-xs-3 col-md-1 text-center"
                        style="padding: 5px; background-color: #5E2028; height: 65px;">
                        <span style="color: #ffffff;">RAL 3005<br>
                            Красное вино</span>
                    </div>
                    <!-- <div class="col-xs-3 col-md-1 text-center"
                        style="padding: 5px; background-color: #6d312b; height: 65px;">
                        <span style="color: #ffffff;">RAL 3009<br>
                            Красная окись</span>
                    </div>
                    <div class="col-xs-3 col-md-1 text-center"
                        style="padding: 5px; background-color: #791f24; height: 65px;">
                        <span style="color: #ffffff;">RAL 3011<br>
                            Коричнево-красный</span>
                    </div>
                    <div class="col-xs-3 col-md-1 text-center"
                        style="padding: 5px; background-color: #bf111b; height: 65px;">
                        <span style="color: #ffffff;">RAL 3020<br>
                            Красный насыщенный</span>
                    </div>
                    <div class="col-xs-3 col-md-1 text-center"
                        style="padding: 5px; background-color: #162e7b; height: 65px;">
                        <span style="color: #ffffff;">RAL 5002<br>
                            Ультрамарин</span>
                    </div> -->
                    <div class="col-xs-3 col-md-1 text-center"
                        style="padding: 5px; background-color: #005486; height: 65px;">
                        <span style="color: #ffffff;">RAL 5005<br>
                            Сигнальный синий</span>
                    </div>
                    <div class="col-xs-3 col-md-1 text-center"
                        style="padding: 5px; background-color: #0071b5; height: 65px;">
                        <span style="color: #ffffff;">RAL 5015<br>
                            Синее небо</span>
                    </div>
                    <!-- <div class="col-xs-3 col-md-1 text-center"
                        style="padding: 5px; background-color: #138992; height: 65px;">
                        <span style="color: #ffffff;">RAL 5018<br>
                            Бирюзово-синий</span>
                    </div>
                    <div class="col-xs-3 col-md-1 text-center"
                        style="padding: 5px; background-color: #00747d; height: 65px;">
                        <span style="color: #ffffff;">RAL 5021<br>
                            Синяя вода</span>
                    </div>
                    <div class="col-xs-3 col-md-1 text-center"
                        style="padding: 5px; background-color: #6391b0; height: 65px;">
                        <span style="color: #ffffff;">RAL 5024<br>
                            Синяя пастель</span>
                    </div>
                    <div class="col-xs-3 col-md-1 text-center"
                        style="padding: 5px; background-color: #276230; height: 65px;">
                        <span style="color: #ffffff;">RAL 6002<br>
                            Зеленый лист</span>
                    </div> -->
                    <div class="col-xs-3 col-md-1 text-center"
                        style="padding: 5px; background-color: #0e4438; height: 65px;">
                        <span style="color: #ffffff;">RAL 6005<br>
                            Зеленый мох</span>
                    </div>
                    <div class="col-xs-3 col-md-1 text-center"
                        style="padding: 5px; background-color: #4b9b3e; height: 65px;">
                        <span style="color: #ffffff;">RAL 6018<br>
                            Желто-зеленый</span>
                    </div>
                    <!-- <div class="col-xs-3 col-md-1 text-center"
                        style="padding: 5px; background-color: #005c54; height: 65px;">
                        <span style="color: #ffffff;">RAL 6026<br>
                            Зеленый опал</span>
                    </div>
                    <div class="col-xs-3 col-md-1 text-center"
                        style="padding: 5px; background-color: #77bbbd; height: 65px;">
                        <span style="color: #ffffff;">RAL 6027<br>
                            Легкий зеленый</span>
                    </div>
                    <div class="col-xs-3 col-md-1 text-center"
                        style="padding: 5px; background-color: #006f43; height: 65px;">
                        <span style="color: #ffffff;">RAL 6029<br>
                            Зеленая мята</span>
                    </div> -->
                    <div class="col-xs-3 col-md-1 text-center"
                        style="padding: 5px; background-color: #999a9f; height: 65px;">
                        <span style="color: #ffffff;">RAL 7004<br>
                            Серый</span>
                    </div>
                    <div class="col-xs-3 col-md-1 text-center"
                        style="padding: 5px; background-color: #6d7270; height: 65px;">
                        <span style="color: #ffffff;">RAL 7005<br>
                            Мышиный</span>
                    </div>
                    <div class="col-xs-3 col-md-1 text-center"
                        style="padding: 5px; background-color: #45494e; height: 65px;">
                        <span style="color: #ffffff;">RAL 7024<br>
                            Графитовый серый</span>
                    </div>
                    <div class="col-xs-3 col-md-1 text-center"
                        style="padding: 5px; background-color: #c4caca; height: 65px;">
                        <span style="color: #000000;">RAL 7035<br>
                            Серый графит</span>
                    </div>
                    <div class="col-xs-3 col-md-1 text-center"
                        style="padding: 5px; background-color: #CFD0CF; height: 65px;">
                        <span style="color: #000000;">RAL 7047<br>
                            Серый</span>
                    </div>
                    <div class="col-xs-3 col-md-1 text-center"
                        style="padding: 5px; background-color: #45302b; height: 65px;">
                        <span style="color: #ffffff;">RAL 8017<br>
                            Коричневый шоколад</span>
                    </div>
                    <!-- <div class="col-xs-3 col-md-1 text-center"
                        style="padding: 5px; background-color: #3b3332; height: 65px;">
                        <span style="color: #ffffff;">RAL 8019<br>
                            Серо-коричневый</span>
                    </div> -->
                    <div class="col-xs-3 col-md-1 text-center"
                        style="padding: 5px; background-color: #dadbd5; height: 65px;">
                        <span style="color: #000000;">RAL 9002<br>
                            Серо-белый</span>
                    </div>
                    <div class="col-xs-3 col-md-1 text-center"
                        style="padding: 5px; background-color: #f8f9fb; height: 65px;">
                        <span style="color: #000000;">RAL 9003<br>
                            Белый</span>
                    </div>
                    <!-- <div class="col-xs-3 col-md-1 text-center"
                        style="padding: 5px; background-color: #151619; height: 65px;">
                        <span style="color: #ffffff;">RAL 9005<br>
                            Черный темный</span>
                    </div> -->
                    <div class="col-xs-3 col-md-1 text-center"
                        style="padding: 5px; background-color: #A5A8A6; height: 65px;">
                        <span style="color: #000000;">RAL 9006<br>
                            Темный серый</span>
                    </div>
                    <div class="col-xs-3 col-md-1 text-center"
                        style="padding: 5px; background-color: #F7F9EF; height: 65px;">
                        <span style="color: #000;">RAL 9010<br>
                            Чистый белый</span>
                    </div>
                    <div class="col-xs-3 col-md-1 text-center"
                        style="padding: 5px; background-color: #f3f6f6; height: 65px;">
                        <span style="color: #000000;">RAL9016<br>
                            Ярко-белый</span>
                    </div>
                </div>

            </div>
        </div>

        <br><br>
        <h5>ВИДЫ ЗАЩИТНО-ДЕКОРАТИВНЫХ ПОКРЫТИЙ</h5><br>
        <p>Окраска системы покрытия осуществляется по цветовой шкале RAL.</p>
        <p>Оцинкование- для защиты металлических изделий от воздействий окружающей среды и агрессивных факторов на
            поверхность металла наносится слой цинка (оцинкование) ///г/м2. Придаёт металлическим изделиям декоративные
            свойства и дополнительную защиту от коррозии применяют специальные полимерные покрытия.</p>
        <p>Полиэфир - в качестве заготовки используется оцинкованный лист. Применимость для большинства строительных
            конструкций в различных климатических зонживает самую низкую темах. Имеет гладкую, полуматовую поверхность.
        </p>
        <p>Текстурированный полиэфир - в качестве заготовки используется оцинкованный лист. Текстура поверхности придает
            покрытию привлекательный внешний вид. По сравнению со стандартным полиэфиром толстослойный полиэфир более
            долговечное покрытие. Применяется в районах с агрессивным воздействием окружающей среды.</p>
        <p>Пластизоль - в качестве заготовки используется оцинкованный лист. Полуматовая, текстурированная поверхность,
            которая не боится больших перепадов температур, выдерпературу –60°C. Отличается высокой гибкостью и
            формируемостью, высокой абразивной стойкостью покрытия, может использоваться в районах со снежными и
            песчаными бурями. Обладает хорошей атмосферной, химической и коррозионной стойкостью.</p>
        <p>ПВДФ - в качестве заготовки используется оцинкованный лист. Гладкая полуматовая поверхность. Покрытие на
            основе поливинилиденфторида и акрила. Это покрытие обладает отличной стойкостью к ультрафиолетовому
            излучению, является самым долговечным покрытием. Обладает низким грязеудержанием, высокой стойкостью к
            механическим повреждениям. Самое дорогое покрытие.</p>
    </div>
    <br>


    <div class="container corrugated-table mb-0 ml-3">
        <h5>ТЕХНИЧЕСКИЕ ХАРАКТЕРИСТИКИ</h5><br>
        <div class="row">
            <div class="col-2 border border-right-0 text-center px-1 py-3" style="max-width: 13%;">
                Обозначение профлиста
            </div>
            <div class="col-1 border  border-right-0 text-center px-1 py-2" style="max-width: 7%;">
                Площадь сечения, А, см<sup>2</sup>
            </div>
            <div class="col-1 border  border-right-0 text-center px-1 py-2" style="max-width: 7%;">
                Масса 1м длины, кг
            </div>
            <div class="col-1 border  border-right-0 text-center px-1 py-2" style="max-width: 7%;">
                Масса 1м<sup>2</sup>, кг
            </div>
            <div class="col-6 border  border-left-0 text-center">

                <div class="row">
                    <div class="col-sm-12 border border-bottom-0 border-right-0 border-top-0 text-center">
                        Справочные величины на 1м ширины
                        <div class="row">
                            <div class="col-8 col-sm-6 border border-bottom-0 border-left-0 border-right-0 text-center">
                                при сжатых узких полках
                                <div class="row">
                                    <div
                                        class="col-8 col-sm-6 border border-left-0 border-right-0 border-bottom-0 text-center">
                                        Момент инерции, J<sub>x</sub>, см<sup>4</sup>
                                    </div>
                                    <div class=" col-4 col-sm-6 border border-bottom-0 border-right-0 text-center">
                                        Момент сопротивления
                                        <div class="row">
                                            <div
                                                class="p-0 col-8 col-sm-6 border border-bottom-0 border-left-0 border-right-0 text-center">
                                                W<sub>x1</sub>, см<sup>3</sup>
                                            </div>
                                            <div
                                                class="p-0 col-4 col-sm-6 border border-right-0 border-bottom-0 text-center">
                                                W<sub>x2</sub>, см<sup>3</sup>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-4 col-sm-6 border border-bottom-0  border-right-0">
                                при сжатых широких полках
                                <div class="row">
                                    <div class="col-8 col-sm-6 border border-left-0 border-right-0 border-bottom-0">
                                        Момент инерции, J<sub>x</sub>, см<sup>4</sup>
                                    </div>
                                    <div class="col-4 col-sm-6 border border-right-0 border-bottom-0">
                                        Момент сопротивления
                                        <div class="row">
                                            <div
                                                class="p-0 col-8 col-sm-6 border border-bottom-0 border-left-0 border-right-0">
                                                W<sub>x1</sub>, см<sup>3</sup>
                                            </div>
                                            <div class="p-0 col-4 col-sm-6 border border-right-0 border-bottom-0">
                                                W<sub>x2</sub>, см<sup>3</sup>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row" *ngFor="let item of items;">
            <div class="col-2 px-2 border border-top-0" style="max-width: 13.1%;">{{item.FIELD1}}</div>
            <div class="col-1 px-3 border border-top-0 border-left-0" style="max-width: 7%;">{{item.FIELD2}}</div>
            <div class="col-1 px-3 border border-top-0 border-left-0" style="max-width: 7%;">{{item.FIELD3}}</div>
            <div class="col-1 px-3 border border-top-0 border-left-0" style="max-width: 7%;">{{item.FIELD4}}</div>
            <div class="col-1 px-5 border border-top-0 border-left-0" style="min-width: 12.6%;">{{item.FIELD5}}</div>
            <div class="col-1 border border-top-0 border-left-0" style="max-width: 6.2%;">{{item.FIELD6}}</div>
            <div class="col-1 border border-top-0 border-left-0" style="max-width: 6.2%;">{{item.FIELD7}}</div>
            <div class="col-1 px-5 border border-top-0 border-left-0" style="min-width: 12.5%;">{{item.FIELD8}}</div>
            <div class="col-1 border border-top-0 border-left-0" style="max-width: 6.2%;">{{item.FIELD9}}</div>
            <div class="col-1 border border-top-0 border-left-0" style="max-width: 6.2%;">{{item.FIELD10}}</div>
            <div class="col-1"></div>
        </div>
        <br>
        <br>
        <br>
    </div>
</div>
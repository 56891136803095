import {Injectable} from "@angular/core";
import {HttpClient, HttpParams } from "@angular/common/http";
// import 'rxjs/add/operator/map';
// import {News} from "../../shared/models/news.model";
// import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class NewsService {
  constructor(private http: HttpClient) {}

  //метод получения новостей
  getNews() {
    return this.http.get('//specialist-kazan.ru/newsdata');
}
  //метод получения новостей по Id
getNewsByID(id:  string){
  return this.http.get('//specialist-kazan.ru/newsdata/'+id);
 }
//метод получения данных о просмотрах новостей
getNewsView(newsView: Int32Array){
  return this.http.get('//specialist-kazan.ru/newsdata/'+newsView);
}

  //метод получения определенного количества новостей
getNewsService(count: Number){
  return this.http.get('//specialist-kazan.ru/newsdata/count/'+count);
}
}

import { Component, OnInit } from '@angular/core';
import { AwardsService } from  '../../core/service/awards.service'
import { Awards } from '../../shared/models/awards.model';
import { Lightbox,LightboxConfig  } from 'ngx-lightbox';

@Component({
  selector: 'app-awards-carousel',
  templateUrl: './awards-carousel.component.html',
  styleUrls: ['./awards-carousel.component.scss']

})
export class AwardsCarouselComponent implements OnInit {
  loading = false;
  _albums: Array<any> = [];

  awardsDataCollection:Awards[] = [];
  constructor(private awardsService: AwardsService, private _lightbox: Lightbox,private _lightboxConfig: LightboxConfig) { }

  //вызов метода получения данныхх о наградахх
  ngOnInit() {
    this.loading = true
    this.awardsService.getAwards()
      .subscribe((data:any)=>{
        data.forEach(awardsDataElement => {
        var awardDataElement =(new Awards().deserialize(awardsDataElement));
        const album = {
          src: awardDataElement.srcAwards,
         caption: awardDataElement.awardsName,
         thumb: awardDataElement.srcAwards,
         alt: awardDataElement.awardsName
        
      };
      this._albums.push(album);
      this.loading = false
        });
      },
        );
    }
    open(index: number): void {
      // open lightbox
      this._lightbox.open(this._albums, index);
    }
   
    close(): void {
      // close lightbox programmatically
      this._lightbox.close();
    }
  }

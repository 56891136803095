import {Deserializable} from "./deserializable.model";

export class Objects implements Deserializable {
  
  id: Int32Array; //id новости
  imageMaxSrc: string;  //ссылка на крупное изображение
  caption: string;  //название объекта
  thumbSrc: string;   // ссылка на превью
  textBgColor:string; // цвет заливки текста для стиля

     deserialize(input: any) {
        Object.assign(this, input);
        return this;
      }
}
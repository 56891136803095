<div>
  <!-- <div>
    <div class="container bg-warning p-2">
      <div class="row">
        <div class="col-sm text-center text-uppercase font-weight-bold">Мы работаем дистанционно и всегда на связи!</div>
      </div>
      <div class="row p-2">
        <div class="col-sm text-center font-weight-bold">
          Фаттахова Гулия +7(927)242-03-10
        </div>
        <div class="col-sm text-center font-weight-bold">
          Мордвинцева Ирина +7(927)249-50-07
        </div>
        <div class="col-sm text-center font-weight-bold">
          Фофонов Олег +7(919)690-10-85
        </div>
      </div>
    </div>
  </div> -->
  <nav id="layer2" class="navbar navbar-expand-sm navbar-expand-md navbar-light">
    <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarTogglerDemo01"
      aria-controls="navbarTogglerDemo01" aria-expanded="false" aria-label="Toggle navigation">
      <span class="navbar-toggler-icon"></span>
    </button>
    <div class="collapse navbar-collapse" id="navbarTogglerDemo01">
      <ul class="navbar-nav m-auto">

        <li class="nav-item">
          <a class="nav-link" routerLink="/about">О компании <span class="sr-only">(current)</span></a>
        </li>
        <li class="nav-item dropdown">
          <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-toggle="dropdown"
            aria-haspopup="true" aria-expanded="false">
            Продукция
          </a>
          <div class="dropdown-menu" aria-labelledby="navbarDropdown">
            <a class="dropdown-item" routerLink="/production/pir-panels">Панели с пенополиизоциануратом</a>
            <a class="dropdown-item" routerLink="/production/teplant-duet">Навесная фасадная система</a>
            <a class="dropdown-item" routerLink="/production/stenovie-paneli">Стеновые панели</a>
            <a class="dropdown-item" routerLink="/production/krovelnie-paneli">Кровельные панели</a>
            <a class="dropdown-item" routerLink="production/fason">Фасонные элементы</a>
            <a class="dropdown-item" routerLink="/production/harpoon">Крепеж</a>
            <a class="dropdown-item" routerLink="/production/corrugated-board">Профнастил</a>
            <a class="dropdown-item" routerLink="/production/sibalux">Композитные панели Sibalux</a>
          </div>
        </li>
        <li class="nav-item">
          <a class="nav-link" routerLink="/news">Новости <span class="sr-only">(current)</span></a>
        </li>
        <li class="nav-item">
          <a class="nav-link" routerLink="/objects">Объекты <span class="sr-only">(current)</span></a>
        </li>
        <li class="nav-item">
          <a class="nav-link" routerLink="/certificates">Сертификаты <span class="sr-only">(current)</span></a>
        </li>
        <li class="nav-item">
          <a class="nav-link" routerLink="/technical-documentation">Техническая документация <span
              class="sr-only">(current)</span></a>
        </li>
        <li class="nav-item">
          <a class="nav-link" routerLink="/contacts">Контакты <span class="sr-only">(current)</span></a>
        </li>
      </ul>
    </div>
  </nav>
</div>
import { Component, OnInit, AfterViewInit, ViewChild, ViewChildren, ChangeDetectorRef } from '@angular/core';
import { NguCarousel, NguCarouselConfig } from '@ngu/carousel';
import { NewsService } from 'src/app/core/service/news.service';
import { HttpClient } from '@angular/common/http';
import { News } from '../../shared/models/news.model';

@Component({
  selector: 'app-news-carousel',
  templateUrl: './news-carousel.component.html',
  styleUrls: ['./news-carousel.component.scss']
})
export class NewsCarouselComponent implements OnInit {
  public isDataLoaded: boolean = false;
  name = 'Angular';



  carouselConfig: NguCarouselConfig = {
    grid: { xs: 1, sm: 3, md: 4, lg: 4, all: 0 },
    load: 3,
    interval: {timing: 4000, initialDelay: 3000},
    loop: true,
    touch: true,
    velocity: 0.2
  }

  _items: Array<any> = [];
  newsDataCollection: News[] = [];
  constructor(private newsService: NewsService, private cdr: ChangeDetectorRef, private http: HttpClient) {

  }
 //вызов метода получения новостей
  ngOnInit() {
    this.newsService.getNewsService(6).subscribe((data: any) => {
      data.forEach(newsDataElement => {
        var newDataElement = (new News().deserialize(newsDataElement));
        const item = {
          src: newDataElement.newsImageDataMax,
          newsText: newDataElement.newsText,
          newsDetails: newDataElement.newsDetails,
          date: newDataElement.newsDate,
          newsView: newDataElement.newsView,
          id:newDataElement.id
          // textBgColor: StyleColors.colorsArray[Math.floor(Math.random() * StyleColors.colorsArray.length)]
        };
        this._items.push(item);
      });

    }, (error: any) => { }, () => { this.isDataLoaded = true });
  }
  // open(index: number): void {
  //   // open lightbox
  //   this._lightbox.open(this._items, index);
  // }
 
  // close(): void {
  //   // close lightbox programmatically
  //   this._lightbox.close();
  // }
}



<div class="container"><br>
 <h3>O компании</h3>
 <h4>Опыт на рынке</h4>  
 
 <div class="row">
     <div class="col-9">
        <p>20 лет наша компания комплектует строительные объекты сэндвич-панелями Teplant.</p>
        <p>Сегодня ООО «Специалист-Казань» занимает лидирующие позиции среди компаний-партнёров и является официальным дилером по Республике
             Татарстан ООО «Электрощит-Стройсистема» - производителя сэндвич-панелей Teplant.</p>
        <p>ООО «Электрощит-Стройсистема» входит в производственную группу «Стройсистема» и занимается производством металлоконструкций, металлочерепицы,
             профнастила, сэндвич-панелей, быстровозводимых зданий, блок-контейнеров. </p>
        <p>На всех стадиях строительства здания, от проектирования до монтажа и дальнейшего обслуживания квалифицированные специалисты компании 
            ООО «Специалист-Казань» проконсультируют Вас по любому вопросу, а также предложат оптимальные архитектурные и инженерные решения и рекомендации по отдельным узлам.</p>
        <p>Индивидуальный подход к каждому клиенту повышает управляемость проектом и снижает общие затраты. В процессе взаимовыгодного сотрудничества опытные сотрудники разработают
             наиболее удобные для клиента условия работы над проектом, чтобы обеспечить его успех во всех деталях.</p>   
     </div>

     <div class="col-3 d-flex align-items-center">
        <a
        href="/assets/files/Каталог_технических_решений.pdf" target="_blank"><img id="" src="/assets/images/Каталог_технических_решений copy.jpg" >
    </a>
     </div>
 </div>
 


<h4> Контроль качества</h4> 
<p>На всех стадиях строительства здания, от проектирования до монтажа и дальнейшего обслуживания квалифицированные специалисты компании
     ООО «Специалист-Казань» проконсультируют Вас по любому вопросу, а также предложат оптимальные архитектурные и инженерные решения и 
     рекомендации по отдельным узлам.</p>     
     
<h4>Индивидуальные скидки</h4>
<p>Индивидуальный подход к каждому клиенту повышает управляемость проектом и снижает общие затраты. В процессе взаимовыгодного сотрудничества 
    опытные сотрудники разработают наиболее удобные для клиента условия работы над проектом, чтобы обеспечить его успех во всех деталях.</p>

<h4>Доверие профессионалов</h4>
<p>Наша компания принимала и принимает участие в строительстве следующих объектов: Международный конно-спортивный комплекс, ТЦ «Мегастрой», 
    торговая сеть магазинов «Эдельвейс», Управление федеральной регистрационной службы по РТ, футбольно-легкоатлетический комплекс, автосалоны 
    «КАН-Авто», «Тойота», «Шкода», Рамстор-14 (Севастопольский пр-т, г.Москва), Рамстор-15 (Пр.Вернадского, г.Москва), «Завод по сборке 
    автомобилей Тойота» (г.Санкт-Петербург), завод «General Motors» (г.Санкт-Петербург), ОГК-4 EON Яйвинская ГРЭС (Пермский край), торговый 
    комплекс «Зельгрос» (г.Казань), Казанский вертолетный завод, Камский бекон (г.Набережные Челны), Челны-Бройлер (г.Набережные Челны), сеть 
    торговых центров «Эссен», международный аэропорт «Казань», комплекс по производству аммиака, метанола и гранулированного карбамида 
    «Аммоний» (г. Менделеевск), КАПО им. Горбунова (г.Казань), Распределительный центр «Havi Logistiks» (ОЭЗ «Алабуга»), объекты Универсиады 
    (ДВВС, конно-спортивный комплекс «Казань», комплекс пулевой стрельбы, центр бокса, ЦГВС, центр гимнастики, центр волейбола
     «Санкт-Петербург»), торговый центр «Леруа Мерлен» (г.Набережные Челны), Казанская ТЭЦ-2. Строительство ПГУ 220 МВт, производственно-складской
      комплекс «Huhtamaki» (ОЭЗ «Алабуга»), завод «Ford» (ОЭЗ «Алабуга»), Иннополис (г.Казань) , стадион КамАЗ г. Набережные Челны.</p>
<br>
 <h4>ПРЕИМУЩЕСТВА ИСПОЛЬЗОВАНИЯ</h4>
 <br>
      <div class="advantages">
        <div class="advantages__column">
            <div class="advantages__item">
                <img src="assets/images/advantage-1.png" alt="" width="40" height="40" class="advantages__pic">
                <h3>Технологичность и скорость</h3>
                <p>Процесс строительства быстровозводимых зданий заключается в сборке металлического каркаса и монтаже ограждающих конструкций с использованием декоративных фасонных элементов. По срокам строительства альтернативы сэндвич-панелям сегодня не существует!</p>
            </div>
            <div class="advantages__item">
                <img src="assets/images/advantage-2.png" alt="" width="40" height="40" class="advantages__pic">
                <h3>Пожарная безопасность</h3>
                <p>Благодаря повышенной огнестойкости сэндвич-панели Teplant можно использовать в качестве противопожарных перегородок. (СЕРТИФИКАТ)</p>
            </div>
            <div class="advantages__item">
                <img src="assets/images/advantage-3.png" alt="" width="40" height="40" class="advantages__pic">
                <h3>Для любого климата</h3>
                <p>Сэндвич-панели Teplant могут эксплуатироваться как при экстремально низких температурах Крайнего Севера, так и при температурных режимах южных регионов. При этом отличные теплоизоляционные свойства панелей обеспечивают минимальные затраты на отопление помещений.</p>
            </div>
            <div class="advantages__item">
                <img src="assets/images/advantage-4.png" alt="" width="40" height="40" class="advantages__pic">
                <h3>Прочность и долговечность</h3>
                <p>Срок службы - не менее 25 лет. Использование в производстве сэндвич-панелей Teplant негорючего теплоизоляционного материала – минеральной ваты на основе базальтового волокна – позволяет свести к минимуму влияние на здание внешних факторов, повысить энергосберегающие характеристики, сократить пожарную нагрузку.</p>
            </div>
        </div>
        <div class="advantages__column">
            <div class="advantages__item">
                <img src="assets/images/advantage-5.png" alt="" width="40" height="40" class="advantages__pic">
                <h3>Уникальность</h3>
                <p>Повышенная огнестойкость и влагостойкость, высокое звукопоглощение, низкая теплопроводность, высокая прочностью конструкции – делают сэндвич-панели универсальным материалом для строительства объектов любой сложности.</p>
            </div>
            <div class="advantages__item">
                <img src="assets/images/advantage-6.png" alt="" width="40" height="40" class="advantages__pic">
                <h3>Снижение затрат на строительство</h3>
                <p>Вес сэндвич-панелей значительно ниже, чем других стройматериалов. Поэтому транспортировать их гораздо проще, чем тонны кирпича, цемента и т.п. При монтаже не требуется применение тяжелой строительной техники, а облегченный фундамент значительно снижает затраты на капитальное строительство.</p>
            </div>
            <div class="advantages__item">
                <img src="assets/images/advantage-7.png" alt="" width="40" height="40" class="advantages__pic">
                <h3>Сейсмоустойчивость</h3>
                <p>Здания, построенные из сэндвич-панелей прекрасно зарекомендовали себя на проблемных грунтах: меньший вес делает их более устойчивыми. Панели Teplant могут применяться и при строительстве объектов в регионах с повышенной сейсмической активностью – до 8-9 баллов, что подтверждено Заключением испытательного Центра «СейсмоФОНД» и Российским национальным комитетом сейсмостойкого строительства.</p>
            </div>
        </div>
    </div>
<h4>АРХИТЕКТУРНЫЕ ВОЗМОЖНОСТИ</h4><br>   
<ul>
    <li>Гладкая поверхность панели придает любому архитектурному строению лаконичный вид, его ровные геометрические линии говорят о стабильности и надежности. А использование сэндвич-панелей Teplant с профилированной поверхностью полностью изменит облик фасада: их ритмичный рисунок создает на нем эффектную игру света и тени.</li>
    <li>Окраска металла в яркие и необычные цвета или использование защитно-декоративных покрытий позволяют воплотить в жизнь самые необычные архитектурные проекты.</li>
    <li>Использование угловых сэндвич-панелей Teplant придаст объекту законченную форму. Любые переходы плоскостей на фасаде: углы, колонны, пилястры – могут быть выполнены как с прямым, так и с закругленным углом. При этом величина поворота плоскости фасадных панелей может быть совершенно любой.</li>
    <li>Сэндвич-панели Teplant можно монтировать как горизонтально, так и вертикально. При этом горизонтальное расположение панелей создает впечатление обтекаемости здания, визуально его расширяя, а вертикальное – удлиняет сооружение, демонстрируя превосходство и стремление вверх, к достижению целей.</li>
    <li>Накладные фасонные элементы, скрывая стыки между панелями, завершают облик фасада здания и создают дополнительный декоративный эффект в его оформлении.</li>
  </ul>

<br><br></div>

import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-stenovie-paneli',
  templateUrl: './stenovie-paneli.component.html',
  styleUrls: ['./stenovie-paneli.component.scss']
})
export class StenoviePaneliComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
